import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { map, take } from "rxjs/operators"
import { CustomTheme } from "../models/custom-theme.model"
import { plainToClass } from 'class-transformer'
import { ProgramService } from "./program.service"

const defaultThemeParams = {
  main_color: "#F8D830",
  failure_color: "#F5523A",
  // button colors
  btn_color_primary: "#F8D830",
  btn_color_secondary: "#F5F4F2",
  btn_color_disabled: "#EEEEEE",
  // button text colors
  btn_text_color_primary: "#000000",
  btn_text_color_secondary: "#595959",
  btn_text_color_disabled: "#C4C4C4",
}

@Injectable({
  providedIn: "root"
})
export class ThemeService {
  private customThemeSubject = new BehaviorSubject<CustomTheme>(null)
  readonly customTheme$ = this.customThemeSubject.asObservable()
  theme$: Observable<CustomTheme>

  constructor(
    private programService: ProgramService
  ) {
    const defaultTheme = plainToClass(CustomTheme, defaultThemeParams)
    this.theme$ = this.customTheme$.pipe(map(t => t || defaultTheme))
  }

  async loadCustomTheme(uuid: string) {
    const currentTheme = this.customThemeSubject.getValue()
    if (!currentTheme) {
      const theme = await this.programService.getThemeConfig(uuid).pipe(take(1)).toPromise()
      this.customThemeSubject.next(theme)
    }
  }
}