import { Expose, Type } from 'class-transformer'
import { Transition } from "./transition.model"

export abstract class InteractiveItem {
  @Expose() id: number
  @Expose({ name: "url" }) itemUrl: string

  @Expose({ name: "end_transition" })
  @Type(() => Transition)
  endTransition: Transition

  @Expose({ name: "button_transitions" })
  @Type(() => Transition)
  buttonTransitions: Transition[]

  connectedItemIds: number[]
  contentUrls: string[]

  kind: string

  init() {
    this.connectedItemIds = this._connectedItemIds()
    this.contentUrls = this._contentUrls()
  }

  get isLast(): boolean {
    return !this.endTransition && this.buttonTransitions.length == 0
  }

  private _connectedItemIds(): number[] {
    var ids = [
      this?.endTransition?.target?.targetInteractiveContentItemId
    ]
    .concat(this.buttonTransitions.map(t => t.target.targetInteractiveContentItemId))
    .filter(id => !!id)

    return [...new Set(ids)]
  }

  protected abstract _contentUrls(): string[]
}
