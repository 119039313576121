<div class="page-container">
  <div class="page">
    <div class="page-content-wrap">
      <div class="page-content page-content--quiz-result">
        
        <svg-quiz-completed-failure></svg-quiz-completed-failure>
        
        <div class="quiz-result quiz-result--failure">
          <p>Ваш результат</p>
          <p>{{ correctAnswersCount$ | ngrxPush}} из {{ questionsCount$ | ngrxPush}}</p>
        </div>

        <p class="quiz-result-caption">
          Этого недостаточно для успешного тестирования.<br>
          Вам нужно пройти тест заново!
        </p>
      </div>
    </div>

    <!-- navigation -->
    <div class="registration-navigation">
      <!-- Кнопка назад -->
      <navigation-button *ngIf="!(prevHidden$ | ngrxPush)"
      type="secondary"
      (click)="prev()">
        Назад
      </navigation-button>

      <!-- Кнопка вперед -->
      <navigation-button 
      type="primary"
      [full]="prevHidden$ | ngrxPush"
      (click)="restart()"
      >
        Пройти снова
      </navigation-button>
    </div>
  </div>
</div>