import { Expose, Type } from 'class-transformer'
import { MultiselectQuestion } from './multiselect-question.model'
import { Question } from './question.model'
import { SelectQuestion } from './select-question.model'
import { SessionExam } from './session-exam.model'

export class ExamStartResponse {
  @Expose({ name: "session_exam" })
  @Type(() => SessionExam)
  sessionExam: SessionExam

  @Expose()
  @Type(() => Question, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: SelectQuestion, name: "select" },
        { value: MultiselectQuestion, name: "multiselect" }
      ]
    }
  })
  questions: Question[]
}