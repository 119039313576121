import { Expose } from 'class-transformer'

type ExamState = "started"|"processing"|"finished"

export class SessionExam {
  @Expose({ name: "exam_state" }) examState: ExamState
  @Expose() uuid: string
  @Expose() grade: number
  @Expose() score: number
  @Expose({ name: "max_score" }) maxScore: number
  @Expose() passed: boolean

  get isStarted(): boolean {
    return this.examState == "started"
  }
}