import { Component, Input, Output, EventEmitter, SimpleChanges } from "@angular/core"
import { AnswerOption, SelectQuestion } from 'src/app/core'
import { Observable, of, forkJoin } from 'rxjs'
import { map } from "rxjs/operators"
import { ExamStateService } from '../exam-state.service'
import { StudentAnswer } from "src/app/core/services/exam.service"

@Component({
  selector: "exam-select-question-section-component",
  templateUrl: "./exam-select-question-section.component.html"
})
export class ExamSelectQuestionSectionComponent {
  loading: boolean
  @Input() question: SelectQuestion
  @Output('next') private nextEmiter = new EventEmitter<any>()

  constructor(
    private examState: ExamStateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['question'].currentValue) {
      this.loading = true

      this.loadImages().subscribe(() => {
        this.loading = false
      })
    }
  }

  get answerOptions(): AnswerOption[] {
    return this.question.answerOptions
  }

  questionIndex$ = this.examState.currentQuestionIndex$.pipe(map(i => i + 1))
  questionsCount$ = this.examState.questionsCount$

  next() {
    this.nextEmiter.emit()
  }

  private loadImage(img: HTMLImageElement): Observable<boolean> {
    if (img.complete) {
      return of(true)
    }

    return new Observable(observer => {
      img.addEventListener('load', () => {
        observer.next(true)
        observer.complete()
      })

      img.addEventListener('error', () => {
        observer.next(false)
        observer.complete()
      })
    })
  }

  protected loadImages(): Observable<boolean> {
    let images = Array.from(document.querySelectorAll('img'))
    if (images.length == 0) {
      return of(true)
    }
    return forkJoin(images.map(img => this.loadImage(img))).pipe(map(() => true))
  }
  
  get nextDisabled(): boolean {
    return !this.anyAnswerSelected
  }

  nextBtnCaption$ = this.examState.isLastQuestion$.pipe(map(isLast => {
    return isLast ? "Готово" : "Вперед"
  }))

  get anyAnswerSelected(): boolean {
    return this.selectedAnswer != undefined
  }

  get questionContent(): string {
    return this.question.text
  }

  get showAudio(): boolean {
    return false //this.question.examAudioUrl != null
  }

  get showVideo(): boolean {
    return false //this.question.videoUrl != null
  }

  get audioUrl(): string {
    return null //this.question.examAudioUrl
  }

  get videoUrl(): string {
    return null //this.question.videoUrl
  }

  selectAnswer(answer: AnswerOption) {
    this.question.answerOptions.forEach(o => o.selected = false)
    answer.selected = true
  }

  get selectedAnswer(): AnswerOption {
    return this.question.answerOptions.find(o => o.selected)
  }

  // Выбора ответа
  confirmAnswer() {
    const studentAnswer: StudentAnswer = {
      question_id: this.question.id,
      answered_at: Math.round(Date.now()/1000),
      selected_answer_options_attributes: [this.selectedAnswer].map(o => ({
        answer_option_id: o.id
      }))
    }
    this.examState.confirmAnswer(studentAnswer)
    this.next()
  }
}