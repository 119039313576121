import { Component, Input } from "@angular/core"
import { ProgramSection, QuizService } from "src/app/core"
import { QuizSectionStore } from "./quiz-section.store"
import { QuizStateService } from "./quiz-state.service"

@Component({
  selector: "quiz-section-component",
  providers: [QuizStateService, QuizSectionStore],
  templateUrl: "./quiz-section.component.html"
})
export class QuizSectionComponent {
  @Input() section: ProgramSection
  loading$ = this.quizStore.loading$
  currentQuestion$ = this.quizStore.currentQuestion$
  quizCompleted$ = this.quizStore.quizCompleted$
  quizSuccess$ = this.quizStore.quizSuccess$
  quiz$ = this.quizStore.quiz$

  constructor(
    private quizService: QuizService,
    private quizStore: QuizSectionStore
  ) {
    
  }

  ngOnInit() {
    const quizId = this.section.contentId
    this.quizService.getQuiz(quizId).subscribe(quiz => {
      this.quizStore.init(quiz)
    })
  }
}