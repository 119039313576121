import { Component, Input, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, FormBuilder, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { KioskFormControl } from 'src/app/core'

@Component({
  selector: "long-text-control",
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LongTextControlComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LongTextControlComponent),
    multi: true
  }],
  templateUrl: "./long-text-control.component.html",
  styleUrls: ["./long-text-control.component.sass"]
})
export class LongTextControlComponent implements ControlValueAccessor {
  @Input() config: KioskFormControl
  formCcontrol = this.fb.control(null)

  onTouched: Function = () => {}

  private subs: Subscription[] = []

  constructor (private fb: FormBuilder) {}

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  registerOnChange(onChange: any) {
    this.subs.push(this.formCcontrol.valueChanges.subscribe(onChange))
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formCcontrol.disable()
    }
    else {
      this.formCcontrol.enable()
    }
  }

  writeValue(value: any) {
    if (value) {
      this.formCcontrol.setValue(value, { emitEvent: false })
    }
  }
  
  validate(c: AbstractControl) {
    const value = c.value
    let errors = {}

    if (this.config.required) {
      if (!value || (value as string).trim().length == 0) {
        const required = {
          message: "Обязательное поле"
        }
        errors = { ...errors, required }
      }
    }

    return Object.keys(errors).length == 0 ? null : errors
  }
}