import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { delay, map } from "rxjs/operators"
import { HttpClient } from '@angular/common/http'
import { BaseApiService } from './base-api.service'

export interface EmailConfirmation {
  key?: string
  deny_resend_until?: number
  token?: string
}

@Injectable({
  providedIn: "root"
})
export class EmailConfirmationService extends BaseApiService {

  constructor(private http: HttpClient) {
    super()
  }

  create(email: string, locale: string): Observable<string> {
    const url = this.buildUrl(["email_confirmations"])
    const email_confirmation = { email, locale }

    // return this.http.post(url, email_confirmation).pipe(
    //   map((resp: {
    //     email_confirmation: EmailConfirmation
    //   }) => {
    //     return resp.email_confirmation.key
    //   })
    // )
    const key = Math.round(Math.random() * 1000000).toString()
    return of(key).pipe(delay(1000))
  }

  confirm(key: string, code: string): Observable<string> {
    const url = this.buildUrl(["email_confirmations", "confirm"])
    const email_confirmation = { key, code } 

    // return this.http.patch(url, email_confirmation).pipe(
    //   map((resp: {
    //     email_confirmation: EmailConfirmation
    //   }) => {
    //     return resp.email_confirmation.token
    //   })
    // )
    const token = Math.round(Math.random() * 1000000).toString()
    return of(token).pipe(delay(1000))
  }
}