import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Program, ProgramSection, SessionService, SystemScreenSection } from '../core'
import { Observable } from 'rxjs'
import { mapTo, switchMap, take, tap, map } from 'rxjs/operators'

export interface IError {
  message: string
}

export interface InstructorState {
  completed: boolean
  loading: boolean
  program: Program
  history: string[]
  error: IError
}

@Injectable({
  providedIn: "root"
})
export class InstructorStateStore extends ComponentStore<InstructorState> {
  constructor(
    private sessionService: SessionService
  ) {
    super({
      loading: true,
      completed: false,
      program: null,
      history: [],
      error: null
    })
  }
  /**
   * Loading
   */
  loading$ = this.select(state => state.loading)
  setLoading = this.updater((state, loading: boolean) => ({ ...state, loading }))
  
  /**
   * Program
   */
  program$ = this.select(state => state.program)
  setProgram = this.updater((state, program: Program) => ({ ...state, program }))

  /**
   * Sections Map Index
   */
  sectionsMapIndex$ = this.select(state => state.program ? state.program.sections.reduce((m, s) => {
    m[s.uuid] = s
    return m
  }, {}) : {})

  /**
   * History
   */
  /**
   * Properly update local transitions history
   * Push uuid to history array. Remove last uuid from history array if previous uuid is equal to passed uuid
   * @param history string[] - current local history
   * @param uuid string - uuid to push to history
   * @returns string[] - properly updated local history
   */
   private pushToHistory(history: string[], uuid: string): string[] {
    const historyLength = history.length
    if (historyLength > 2 && history[historyLength - 2] == uuid) {
      return history.slice(0, historyLength - 1)
    } 
    else {
      return [...history, uuid]
    }
  }
  history$ = this.select(state => state.history)
  /**
   * Should be used only for history initialisation
   * @param transitionsHistory string[] - saved history from backend
   */
  setHistory(transitionsHistory: string[]) {
    let history = []
    transitionsHistory.forEach(uuid => {
      history = this.pushToHistory(history, uuid)
    })
    this.patchState({ history })
  }

  /**
   * Error
   */
  readonly error$ = this.select(s => s.error)
  setError = this.updater((s, error: IError) => ({ ...s, error }))

  /**
   * Current section
   */
  currentSectionUuid$ = this.select(
    this.history$,
    (history) => history[history.length - 1]
  )

  currentSection$: Observable<ProgramSection> = this.select(
    this.sectionsMapIndex$, 
    this.currentSectionUuid$, 
    (sectionsMapIndex, currentSectionUuid) => currentSectionUuid ? sectionsMapIndex[currentSectionUuid] : null
  )

  /**
   * Previous section
   */
  prevSection$: Observable<ProgramSection> = this.select(
    this.history$,
    this.sectionsMapIndex$,
    (history, sectionsMapIndex) => {
      const prevSectionUuid = history[history.length - 2]
      return prevSectionUuid ? sectionsMapIndex[prevSectionUuid] : null
    }
  )
  
  isLastSection$ = this.select(
    this.currentSection$, 
    (section) => section ? section.transitions.length == 0 : false
  )

  isFirstSection$ = this.select(
    this.prevSection$, 
    (prevSection) => !prevSection || prevSection.content instanceof SystemScreenSection
  )

  /**
   * Completed
   */
  completed$ = this.select(state => state.completed)
  setCompleted = this.updater((state, completed: boolean) => ({ ...state, completed }))
  
  pushUuid(uuid: string): Observable<boolean> {
    return this.sessionService.pushHistory(uuid)
  }

  /**
   * Next(uuid: string)
   */
  nextSection(uuid: string) {
    this.patchState(state => ({ 
      history: this.pushToHistory(state.history, uuid)
    }))
  }
  next = this.effect((uuids$: Observable<string>) => {
    return uuids$.pipe(
      tap(() => {
        this.setLoading(true)
      }),
      // отправляем в историю переданный uuid
      switchMap(uuid => this.pushUuid(uuid).pipe(
        mapTo(uuid),
        // обновляем локальную историю в зависимости от ситуации
        tap((uuid: string) => this.nextSection(uuid)),
        tapResponse(
          () => {
            this.setLoading(false)
          },
          e => {
            console.log("error", e)
            this.setLoading(false)
          }
        )
      )),
    )
  })

  /**
   * Prev()
   */
  prevSection() {
    this.patchState(state => {
      const history = state.history.slice(0, state.history.length - 1)
      return { history }
    })
  }

  prev = this.effect(requestPrev$ => {
    return requestPrev$.pipe(
      tap(() => this.setLoading(true)),
      switchMap(() => this.prevSection$.pipe(
        take(1), 
        map(s => s.uuid),
        switchMap(uuid => this.pushUuid(uuid)),
        tap(() => this.prevSection()),
        tapResponse(
          () => this.setLoading(false),
          e => {
            console.log("error", e)
            this.setLoading(false)
          }
        )
      ))
    )
  })
}