import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { EMPTY, Observable } from "rxjs";
import { catchError, switchMap, take, map, distinctUntilChanged, filter, tap } from "rxjs/operators";
import { SessionService } from "src/app/core";

const VIDEO_WATCHED_STEP = 15

export interface VideoSectionState {
  id: number
  watched: boolean
  t: number
}

@Injectable()
export class VideoSectionStore extends ComponentStore<VideoSectionState> {
  
  constructor(
    private sessionService: SessionService
  ) {
    super()
  }

  watched$ = this.select(s => s.watched)
  setWatched = this.updater((s, watched: boolean) => ({ ...s, watched }))
  updateWatched = this.effect((watched$: Observable<boolean>) => {
    return watched$.pipe(
      switchMap(watched => this.pushWatched(watched).pipe(
        tap(
          watched => this.setWatched(watched),
          e => console.log("updateWatched error", e)
        ),
        catchError(() => EMPTY)
      ))
    )
  })

  timeWatched$ = this.select(s => s.t)
  setTimeWatched = this.updater((s, timeWatched: number) => ({ ...s, t: timeWatched }))
  updateTimeWatched = this.effect((timeWatched$: Observable<number>) => {
    return timeWatched$.pipe(
      map(timeWatched => Math.floor(timeWatched)),
      tap(timeWatched => this.setTimeWatched(timeWatched)),
      filter(timeWatched => timeWatched % VIDEO_WATCHED_STEP == 0),
      distinctUntilChanged(),
      switchMap(timeWatched => this.pushWatchedTime(timeWatched).pipe(
        catchError(() => EMPTY)
      ))
    )
  })

  private pushWatchedTime(time: number): Observable<boolean> {
    return this.pushVideoState({ t: time })
  }

  private pushWatched(watched: boolean): Observable<boolean> {
    return this.pushVideoState({ watched })
  }

  private pushVideoState(state: Partial<VideoSectionState>): Observable<boolean> {
    return this.state$
      .pipe(
        take(1),
        switchMap(currentState => {
          const { id, t, watched } = currentState
          const key = `video.${ id }`
          const newState = {
            ...{ t, watched }, 
            ...state
          }
          return this.sessionService.pushContentState(key, newState)
        })
      )
  }
}