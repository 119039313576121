import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { iif, Observable, of, throwError } from "rxjs";
import { BaseApiService } from "./base-api.service";
import { concatMap, delay, map, retryWhen, switchMap } from "rxjs/operators";
import { AppStateStore } from "src/app/app-state.store";

@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseApiService {
  constructor(
    private http: HttpClient,
    private appStateStore: AppStateStore
  ) {
    super()
  }

  pushHistory(uuid: string): Observable<boolean> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["sessions", sessionUuid, "transitions_history"])
        const data = { uuid }
        return this.http.patch<{ ok: boolean }>(url, data)
      }),
      map(resp => resp.ok)
    ).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((e, i) => iif(() => i >= 3, throwError(e), of(e).pipe(delay(1000)))) 
      ))
    )
  }

  pushContentState(key: string, state: Object): Observable<boolean> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["sessions", sessionUuid, "content_state"])
        const data = { key, state }
        return this.http.patch<{ ok: boolean }>(url, data)
      }),
      map(resp => resp.ok)
    )
  }
}