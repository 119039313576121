import { Component, Input } from '@angular/core'
import { InstructorStateStore } from '../../instructor-state.store'
import { ExamStateService } from './exam-state.service'
import { map, take } from 'rxjs/operators'
import { Exam, ProgramSection } from 'src/app/core'

@Component({
  selector: "exam-completed-component",
  templateUrl: "./exam-completed.component.html",
  styleUrls: ["./exam-completed.component.sass"]
})
export class ExamCompletedComponent {
  @Input() section: ProgramSection
  exam: Exam
  sessionExam$ = this.examState.sessionExam$
  get showResults() {
    return this.exam.showResults
  }
  score$ = this.sessionExam$.pipe(map(r => r.score))
  maxScore$ = this.sessionExam$.pipe(map(r => r.maxScore))
  percent$ = this.examState.select(
    this.score$,
    this.maxScore$,
    (score, maxScore) => Math.round(score / maxScore * 100)
  )
  examPassed$ = this.sessionExam$.pipe(map(r => r.passed))

  constructor(
    private instructorStateStore: InstructorStateStore,
    private examState: ExamStateService
  ) {}

  ngOnInit() {
    this.exam = this.section.content as Exam
  }

  next() {
    this.sessionExam$.pipe(take(1)).subscribe(sessionExam => {
      const { grade, score, passed } = sessionExam
      const transition = this.section.findTransitionByParams({ grade, score, passed }) || this.section.defaultTransition
      if (transition) {
        this.instructorStateStore.next(transition.nextUuid)
      }
    })
  }

  prev() {
    this.instructorStateStore.prev()
  }
}
