<div class="page-container">
  <div class="page">
    <div class="page-content-wrap">
      <div class="page-content page-content--quiz-result">
        
        <svg-quiz-completed-success></svg-quiz-completed-success>
        
        <div class="quiz-result quiz-result--success">
          <p>Ваш результат</p>
          <p>{{ correctAnswersCount$ | ngrxPush }} из {{ questionsCount$ | ngrxPush}}</p>
        </div>

        <p class="quiz-result-caption">
          Отличный результат!
          <br>
          Тест пройден успешно. 
        </p>
      </div>
    </div>

    <!-- navigation -->
    <div class="registration-navigation">
      <!-- Кнопка назад -->
      <navigation-button *ngIf="!(prevHidden$ | ngrxPush)"
      type="secondary"
      (click)="prev()">
        Назад
      </navigation-button>

      <!-- Кнопка вперед -->
      <navigation-button 
      type="primary"
      [full]="prevHidden$ | ngrxPush"
      (click)="next()"
      >
        Далее
      </navigation-button>
    </div>
  </div>
</div>