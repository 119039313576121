<div class="page-container">
  <div class="page page--exam">
    <div class="page-content-wrap">
      <div class="page-content page-content--exam-result">

        <svg-exam-completed *ngIf=!showResults></svg-exam-completed>

        <ng-container *ngIf="showResults">
          <svg-exam-completed-success *ngIf="examPassed$ | async"></svg-exam-completed-success>
          <svg-exam-completed-failure *ngIf="!(examPassed$ | async)"></svg-exam-completed-failure>

          <div class="exam-result" [ngClass]="{
            'exam-result--success': examPassed$ | async,
            'exam-result--failure': !(examPassed$ | async)
          }">
            <p>Ваш результат</p>
            <p class="result-score" *ngIf="exam.displayResults == 'points'">
              {{ score$ | ngrxPush }} из {{ maxScore$ | ngrxPush}}
            </p>

            <p class="result-percent" *ngIf="exam.displayResults == 'percent'">
              {{ percent$ | ngrxPush }} %
            </p>
          </div>
        </ng-container>

        <p class="exam-result-caption">
          Тестирование окончено
        </p>
      </div>
    </div>

    <!-- navigation -->
    <div class="registration-navigation">
      <!-- Кнопка назад -->
      <navigation-button type="secondary" (click)="prev()">
        Назад
      </navigation-button>

      <!-- Кнопка вперед -->
      <navigation-button type="primary" (click)="next()">
        Далее
      </navigation-button>
    </div>
  </div>
</div>