<div class="page-container">
  <div class="page page--quiz">
    <div class="page-content-wrap">
      <div class="page-content page-content__singleselect-question">
        <loader [visible]="loading">Загрузка</loader>
        
        <div class="quiz-header">
          <h1 class="quiz-title">Тест</h1>
          
          <div class="quiz-subtitle" *ngIf="questionsCount$ | async">
            Вопрос: {{ questionIndex$ | async }} / {{ questionsCount$ | async }}
          </div>
        </div>

        <div class="question">
          <div class="question-content" [innerHTML]="questionContent"></div>
        </div>

        <div class="question-answers-list">
          <label class="answer" [ngClass]="{
            'answer__selected': (selectedAnswerOption$ | async) == answer,
            'answer__correct':  answer.correct && (hasAnswer$ | async) && (selectedAnswerOption$ | async) == answer,
            'answer__error':   !answer.correct && (hasAnswer$ | async) && (selectedAnswerOption$ | async) == answer,
            'answer__disabled': hasAnswer$ | async
          }" 
          for="answer-{{answer.id}}"
          *ngFor="let answer of answerOptions">
            <input type="radio" name="selectedAnswer" class="answer-select" 
            id="answer-{{answer.id}}"
            [disabled]="hasAnswer$ | async"
            (click)="selectAnswer(answer)">

            <div class="answer-content" [innerHTML]="answer.text">
            </div>
          </label>
        </div>

        <div class="question-answer-hint" *ngIf="(hasAnswer$ | async)" [ngClass]="{
          'correct': (answerCorrect$ | async),
          'error': !(answerCorrect$ | async)
        }">
          <h5 class="question-answer-hint-title">
            {{ questionAnswerHintTitle$ | async }}
          </h5>

          <p class="question-answer-hint-content" *ngIf="answerHint$ | async as answerHint">
            {{ answerHint }}
          </p>
        </div>
      </div>
    </div>

    <div class="registration-navigation">
      <navigation-button
      type="primary"
      [full]="true"
      [disabled]="!(hasAnswer$ | async)"
      (click)="next()"
      >
        {{ nextBtnCaption$ | async }}
      </navigation-button>
    </div>
  </div>
</div>