import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { Session } from './core'
import { map } from "rxjs/operators"

export interface AppState {
  programUuid: string
  externalId: string
  accessToken: string
  externalParams: Object
  session: Session
}

export interface UrlParams {
  programUuid?: string
  externalId?: string
  accessToken?: string
}

@Injectable({
  providedIn: "root"
})
export class AppStateStore extends ComponentStore<AppState> {
  constructor() {
    super({
      programUuid: null,
      externalId: null,
      accessToken: null,
      externalParams: {},
      session: null
    })
  }

  readonly session$ = this.select(state => state.session)
  readonly sessionUuid$ = this.session$.pipe(map(s => s && s.uuid))
  setSession = this.updater((state, session: Session) => ({ ...state, session }))

  readonly programUuid$ = this.select(s => s.programUuid)
  setProgramUuid = this.updater((s, programUuid: string) => ({ ...s, programUuid }))

  readonly externalId$ = this.select(s => s.externalId)
  setExternalId = this.updater((s, externalId: string) => ({ ...s, externalId }))

  readonly accessToken$ = this.select(s => s.accessToken)
  setAccessToken = this.updater((s, accessToken: string) => ({ ...s, accessToken }))

  readonly externalParams$ = this.select(s => s.externalParams)
  setExternalParams(externalParams: Object) {
    this.setState((state) => {
      return { ...state, externalParams }
    })
  }
}
