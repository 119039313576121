<loader [visible]="loading$ | ngrxPush">Загрузка</loader>

<ng-container *ngIf="quiz$ | async">
  <ng-container *ngIf="!(quizCompleted$ | async) else quizCompleted">
    <quiz-question-component
    [question]="currentQuestion$ | async"
    ></quiz-question-component>
  </ng-container>
  
  <ng-template #quizCompleted>
    <quiz-completed-success-component
    [section]="section"
    *ngIf="(quizSuccess$ | async) else failure"
    ></quiz-completed-success-component>
  
    <ng-template #failure>
      <quiz-completed-failure-component
      [section]="section"
      ></quiz-completed-failure-component>
    </ng-template>
  </ng-template>
</ng-container>