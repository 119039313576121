<instructor-error
*ngIf="error$ | async as error; else noError"
[error]="error"
></instructor-error>

<ng-template #noError>
  <div class="page-container" *ngIf="loading$ | ngrxPush as loading; else programContent">
    <div class="page">
      <div class="page-content-wrap">
        <div class="page-content page-content--system-screen">
          <loader [visible]="loading">Загрузка...</loader>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #programContent>
    <!-- FOO -->
    <program-section-component
    *ngIf="currentSection$ | ngrxPush as section"
    (next)="next()"
    (prev)="prev()"
    [section]="section" 
    ></program-section-component>
  </ng-template>
</ng-template>