import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { plainToClass } from "class-transformer";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AppStateStore } from "src/app/app-state.store";
import { KioskForm } from "../models";
import { BaseApiService } from "./base-api.service";

export interface FormAnswer {
  value: any
  stringValue: string
}

export interface FormAnswers {
  [key:string]: FormAnswer
}

export interface FormSubmitResponse {
  ok?: boolean
  error?: string
  error_description?: string
}

@Injectable({
  providedIn: 'root'
})
export class KioskFormService extends BaseApiService {
  constructor(
    private http: HttpClient,
    private appStateStore: AppStateStore
  ) {
    super()
  }

  getKioskForm(formId: string): Observable<KioskForm> {
    const url = this.buildUrl(["kiosk_forms", formId])

    return this.appStateStore.sessionUuid$.pipe(
      switchMap(sessionUuid => this.http.get(url, {
        headers: {
          "KIOSK-SESSION": sessionUuid
        }
      }).pipe(
        map(data => Object.assign(
          data["kiosk_form"],
          data["meta"]
        )),
        map(json => plainToClass(KioskForm, json, {
          excludeExtraneousValues: true 
        }))
      ))
    )
  }

  submit(formId: string, answers: FormData) {
    const url = this.buildUrl(["kiosk_forms", formId, "submit"])
    
    return this.appStateStore.sessionUuid$.pipe(
      switchMap(sessionUuid => this.http.post<FormSubmitResponse>(url, answers, {
        headers: { "KIOSK-SESSION": sessionUuid }
      }))
    )
  }
}