import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { InstructorComponent } from './instructor.component'


const routes: Routes = [{
  path: "", 
  component: InstructorComponent
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstructorRoutingModule { }
