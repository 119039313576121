import { Expose, Type } from "class-transformer"
import { Content } from "./content.model"
import { KioskFormControl } from "./kiosk-form-control.model"

export class KioskForm extends Content {
  @Expose() uuid: string
  @Expose({ name: "btn_next_label" }) btnNextLabel: string
  @Expose() title: string
  
  @Expose({ name: "kiosk_form_controls" })
  @Type(() => KioskFormControl)
  controls: KioskFormControl[]

  @Expose() submitted: boolean
}