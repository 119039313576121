import { Component, Input } from "@angular/core";
import { IError } from "./instructor-state.store";

@Component({
  selector: "instructor-error",
  styles: [`
    .page-content--system-screen {
      justify-content: center;
      width: 100%;
      max-width: 360px;
    }

    h1 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 400;
      text-align: center;
      margin-top: 64px;
      margin-bottom: 0;
    }

    .error-message {
      text-align: center;
      margin-top: 30px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  `],
  template: `
    <div class="page-container">
      <div class="page page-error">
        <div class="page-content-wrap">
          <div class="page-content page-content--system-screen">
            <svg-error></svg-error>

            <h1>Невозможно продолжить</h1>

            <p class="error-message" [innerText]="error.message"></p>
          </div>
        </div>
      </div>
    </div>
  `
})
export class InstructorErrorComponent {
  @Input() error: IError
}
