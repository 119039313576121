import { Component, Input } from "@angular/core"
import { Interactive, InteractiveItem, ProgramSection } from "src/app/core/models"
import { FilesCacheService, FileCache } from "./files-cache.service"
import { InteractiveStateService } from './interactive-state.service'
import { filter, mapTo, switchMap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { InstructorStateStore } from '../../instructor-state.store'
import { InteractiveService } from 'src/app/core'

@Component({
  selector: "interactive-section-component",
  providers: [
    FilesCacheService,
    InteractiveStateService
  ],
  templateUrl: "./interactive-section.component.html"
})
export class InteractiveSectionComponent {
  @Input() section: ProgramSection
  interactive: Interactive
  loaderText = "Загрузка..."
  loading: boolean
  fallback = false
  itemStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0'
  }
  displayItemsIds: number[] = []
  currentItemId: number

  constructor(
    private cache: FilesCacheService,
    private interactiveStateService: InteractiveStateService,
    private instructorStateStore: InstructorStateStore,
    private interactiveService: InteractiveService
  ) {}

  async ngOnInit() {
    this.loading = true

    this.interactive = await this.interactiveService.getInteractive(this.section.contentId)

    // делает инициализацию интерактива перед запуском
    this.interactive.init()
    console.log("Interactive", this.interactive)
    
    // подписываемся на текущий элемент интерактива
    this.interactiveStateService.currentItem$.pipe(
      filter(item => !!item),
      switchMap(item => this.interactiveService.saveCurrentItem(this.interactive.id, item.id).pipe(
        mapTo(item)
      ))
    ).subscribe(item => {
      const currentItemId = item.id
      // this.setCurrentItemId(currentItemId)
      this.currentItemId = currentItemId
      this.displayItemsIds = [...new Set([currentItemId].concat(item.connectedItemIds))]
    })

    // подписываемся на завершение интерактива
    this.completed$.pipe(filter((c => !!c))).subscribe(() => {
      // следующая секция
      const transition = this.section.defaultTransition
      if (transition) this.instructorStateStore.next(transition.nextUuid)
    })

    // инициализируем сервис
    this.interactiveStateService.init(this.interactive)

    // просим кэш начать загружать файлы
    // сначала должена быть запущена предзагрузка файлов!
    const preloadFileUrls: string[] = [].concat.apply([], this.interactive.items.map(i => i.contentUrls))
    this.cache.preloadFiles([...new Set(preloadFileUrls)])

    // ждем, пока предзагрузятся файлы для первых элементов интерактива
    const firstChunk = this.interactive.items.slice(0, 4)
    const firstChunkUrls: string[] = [].concat.apply([], firstChunk.map(i => i.contentUrls))
    this.cache.getFiles([...new Set(firstChunkUrls)]).subscribe({
      next: (data) => this.updateProgress(data),
      complete: () => this.onFilePreloadingComplete(),
      error: (err) => this.onPreloadingFileError()
    })
  }

  get completed$(): Observable<boolean> {
    return this.interactiveStateService.completed$
  }

  get interactiveItems(): InteractiveItem[] {
    return this.interactive.items
  }

  trackByFn(index: number, item: InteractiveItem):number {
    return item.id
  }

  private updateProgress(data: FileCache[]) {
    const progress = Math.round(data.reduce((memo, d) => memo + d.progress, 0) / data.length)
    this.loaderText = `Загрузка: ${ progress }%`
  }

  private onFilePreloadingComplete() {
    this.loading = false
  }

  private onPreloadingFileError() {
    this.instructorStateStore.setError({
      message: "Ошибка загрузки интерактивного материала"
    })
  }
}