import { Component, Input } from '@angular/core'
import { ThemeService } from "src/app/core/services"
import { pluck } from "rxjs/operators"

@Component({
  selector: 'loader',
  template: `
    <div class="loader" [hidden]="!visible">
      <div class="loader__overlay"></div>
      <div class="loader__spinner">
        <div class="loader__spinner-i"
        [ngStyle]="{
          'border-color': mainColor$ | ngrxPush,
          'border-left-color': 'transparent'
        }"
        ></div>
      </div>

      <div class="loader__text">
        <ng-content></ng-content>
      </div>
    </div>
  `
})

export class LoaderComponent {
  @Input() visible: boolean = false

  mainColor$ = this.themeService.theme$.pipe(pluck("mainColor"))

  constructor(
    private themeService: ThemeService
  ) {}
}
