<div class="page-container">
  <div class="page page--exam">
    <div class="page-content-wrap">
      <div class="page-content page-content__multiselect-question">
        <loader [visible]="loading">Загрузка</loader>

        <div class="quiz-header">
          <h1 class="exam-title">Тестирование</h1>
          
          <div class="exam-subtitle" *ngIf="questionsCount$ | ngrxPush as questionsCount">
            Вопрос {{ questionIndex$ | ngrxPush }} / {{ questionsCount }}
          </div>
        </div>

        <div class="question">
          <div class="question-content" [innerHTML]="questionContent"></div>
        </div>

        <div class="question-answers-list">
          <label class="answer" for="answer-{{answer['id']}}"
          *ngFor="let answer of answerOptions" [ngClass]="{
            'answer__selected': answer.selected
          }"
          >
            <input type="checkbox" name="selected_answer" class="answer-select" 
            id="answer-{{answer.id}}"
            [value]="answer.id"
            [checked]="answer.selected"
            (click)="selectAnswer(answer)">

            <div class="answer-content" 
            [innerHTML]="answer.text"
            ></div>
          </label>
        </div>
      </div>
    </div>

    <div class="registration-navigation">
      <navigation-button type="primary"
      [full]="true"
      [disabled]="nextDisabled"
      (click)="confirmAnswer()"
      >
        {{ nextBtnCaption$ | ngrxPush }}
      </navigation-button>
    </div>
  </div>
</div>