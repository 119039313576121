import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Session } from "src/app/core/models/session.model"
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { plainToClass } from 'class-transformer'
import { BaseApiService } from './base-api.service'


export interface RegistrationFormParams {
  uuid?: string
  external_id?: string
  phone?: string
  email?: string
  telegram?: string
  [key: string]: any
}

export interface RegistrationParams {
  registration_form: RegistrationFormParams
  program_uuid: string
}

@Injectable()
export class RegistrationService extends BaseApiService {

  constructor(private http: HttpClient) {
    super()
  }

  getSession(uuid: string): Observable<Session> {
    const url = this.buildUrl(["sessions", uuid])
    return this.http.get(url).pipe(
      map(resp => {
        return plainToClass(Session, resp["session"], {
          excludeExtraneousValues: true
        })
      })
    )
  }

  createSession(registration: RegistrationParams, params = {}): Observable<Session> {
    const url = this.buildUrl(["sessions"])

    return this.http.post(url, { registration, ...params }).pipe(
      map(resp => {
        const session = plainToClass(Session, resp["session"], {
          excludeExtraneousValues: true
        })
        return session
      })
    )
  }

  finishSession(uuid: string): Observable<Session> {
    const url = this.buildUrl(["sessions", "finish"])
    const params = {
      session_uuid: uuid
    }

    return this.http.patch(url, params).pipe(
      map(resp => {
        const session = plainToClass(Session, resp["session"], {
          excludeExtraneousValues: true
        })
        return session
      })
    )
  }

  completeEducation(uuid: string): Observable<Session> {
    const url = this.buildUrl(["sessions", "complete_education"])
    const params = { session_uuid: uuid }

    return this.http.patch(url, params).pipe(
      map(resp => {
        const session = plainToClass(Session, resp["session"], {
          excludeExtraneousValues: true
        })
        return session
      })
    )
  }
}
