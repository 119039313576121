import { Component, Input } from "@angular/core"
import { HttpErrorResponse } from "@angular/common/http"
import { switchMap, take, map } from 'rxjs/operators'
import { AppStateStore } from 'src/app/app-state.store'
import { Exam, ExamService, ProgramSection } from "src/app/core"
import { InstructorStateStore } from '../../instructor-state.store'
import { ExamStateService } from "./exam-state.service"

@Component({
  selector: "exam-section-component",
  providers: [ExamStateService],
  templateUrl: "./exam-section.component.html"
})
export class ExamSectionComponent {
  @Input() section: ProgramSection
  loading$ = this.examState.loading$
  completed$ = this.examState.completed$
  currentQuestion$ = this.examState.currentQuestion$
  

  constructor(
    private examState: ExamStateService,
    private appState: AppStateStore,
    private examService: ExamService,
    private readonly instructorStateStore: InstructorStateStore
  ) {}

  ngOnInit() {
    this.examState.resetExamState()
    /**
     * стартуем экзамен
     * если экзамен уже пройден - переходим на следующую секцию
     * иначе запускаем экзамен
     */
    this.appState.session$.pipe(
      take(1),
      switchMap(s => this.examService.start(s.uuid, this.section.content.id.toString()))
    )
    .subscribe(data => {
      const { sessionExam, questions } = data
      if (sessionExam.isStarted) {
        this.examState.setExam(this.section.content as Exam)
        this.examState.setSessionExam(sessionExam)
        this.examState.setQuestions(questions)
        this.examState.setLoading(false)
      }
      else {
        this.examState.setSessionExam(sessionExam)
        this.examState.setCompleted(true)
        this.examState.setLoading(false)
      }
    }, err => {
      let message = "Не получилось начать экзамен"
      if (err instanceof HttpErrorResponse) {
        const { error_description } = err.error
        if (error_description) {
          message += `\n${ error_description }`
        }
      }
      this.instructorStateStore.setError({
        message
      })
    })
  }

  finishExam() {
    this.examState.setLoading(true)
    this.appState.select(
      this.appState.session$,
      this.examState.sessionExam$,
      this.examState.studentAnswers$,
      (session, sessionExam, studentAnswers) => ({ session, sessionExam, studentAnswers })
    ).pipe(
      take(1),
      switchMap(({ session, sessionExam, studentAnswers }) => this.examService.finish(
        session.uuid,
        sessionExam.uuid,
        studentAnswers
      ))
    )
    .subscribe(sessionExam => {
      console.log("FINISH EXAM", sessionExam)
      this.examState.setSessionExam(sessionExam)
      this.examState.setCompleted(true)
      this.examState.setLoading(false)
    }, err => {
      this.instructorStateStore.setError({
        message: "Не получилось завершить экзамен"
      })
    })
  }

  next() {
    this.examState.isLastQuestion$.pipe(
      take(1)
    )
    .subscribe(isLastQuestion => {
      if (isLastQuestion) this.finishExam()
      else this.examState.next()
    })
  }

  prev() {
    this.instructorStateStore.prev()
  }
}