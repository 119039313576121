import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { plainToClass } from "class-transformer"
import { iif, Observable, of, throwError } from "rxjs"
import { retryWhen, switchMap, map, concatMap, delay } from "rxjs/operators"
import { AppStateStore } from "src/app/app-state.store"
import { Quiz } from "../models"
import { BaseApiService } from "./base-api.service"

@Injectable({
  providedIn: 'root'
})
export class QuizService extends BaseApiService {
  constructor(
    private http: HttpClient,
    private appStateStore: AppStateStore
  ) {
    super()
  }

  getQuiz(id: number): Observable<Quiz> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["quizzes", id.toString()])
        const headers = { "KIOSK-SESSION": sessionUuid }

        return this.http.get(url, { headers })
      }),
      map(resp => plainToClass(Quiz, resp["lesson_quiz"], { 
        excludeExtraneousValues: true 
      }))
    ).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((e, i) => iif(() => i >= 3, throwError(e), of(e).pipe(delay(1000)))) 
      ))
    )
  }

  sendAnswer(quizId: number, questionId: number, answer: number|number[]): Observable<boolean> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["quizzes", quizId.toString(), "answer"])
        const headers = { "KIOSK-SESSION": sessionUuid }
        const data = { question_id: questionId, answer }

        return this.http.post<{ ok: boolean }>(url, data, { headers })
      }),
      map(resp => resp.ok)
    ).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((e, i) => iif(() => i >= 3, throwError(e), of(e).pipe(delay(1000)))) 
      ))
    )
  }

  restart(quizId: number): Observable<boolean> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["quizzes", quizId.toString(), "restart"])
        const headers = { "KIOSK-SESSION": sessionUuid }

        return this.http.post<{ ok: boolean }>(url, null, { headers })
      }),
      map(resp => resp.ok)
    ).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((e, i) => iif(() => i >= 3, throwError(e), of(e).pipe(delay(1000)))) 
      ))
    )
  }
}