import { Component, Input, Output, EventEmitter, Attribute } from '@angular/core'
import { Subject, BehaviorSubject, Observable, combineLatest, Subscription } from "rxjs"
import { tap, map, mapTo, switchMap, filter, take, pluck } from "rxjs/operators"
import { ThemeService } from "src/app/core/services"

type ButtonType = "primary"|"secondary"

@Component({
  selector: "navigation-button",
  template: `
  <button
  [style.width]="this.full ? '100%' : '50%'"
  [style.background-color]="backgroundColor$ | async"
  [style.color]="textColor$ | async"
  [style.cursor]="cursor$ | async"
  (mouseover)="hoverSubject.next(true)" 
  (mouseleave)="hoverSubject.next(false)"
  (click)="click($event)"
  >
    <ng-content></ng-content>
  </button>
  `,
  styles: [`
    button {
      height: 64px;
      font-size: 18px;
      cursor: pointer;
      border: none;
    }
  `]
})
export class NavigationButton {
  @Input() full = false
  @Input() set disabled(v: boolean) { this.disabledSubject.next(v) }
  @Output('click') private clickEmiter = new EventEmitter<any>()
  // disabled
  private disabledSubject = new BehaviorSubject<boolean>(false)
  readonly disabled$ = this.disabledSubject.asObservable()
  // button clicks
  readonly clickSubject = new Subject<PointerEvent>()
  // hover
  readonly hoverSubject = new BehaviorSubject<boolean>(false)
  readonly hover$ = this.hoverSubject.asObservable()
  // dynamic styles
  backgroundColor$: Observable<string>
  textColor$: Observable<string>
  readonly cursor$ = this.disabled$.pipe(map(d => d ? "not-allowed" : "pointer"))
  private subs: Subscription[] = []

  constructor(
    @Attribute('type') public type: ButtonType,
    private themeService: ThemeService
  ) {
    const theme$ = this.themeService.theme$,
          btnColorPrimary$ = theme$.pipe(pluck('btnColorPrimary')),
          btnColorSecondary$ = theme$.pipe(pluck('btnColorSecondary')),
          btnColorDisabled$ = theme$.pipe(pluck('btnColorDisabled')),
          btnTextColorPrimary$ = theme$.pipe(pluck('btnTextColorPrimary')),
          btnTextColorSecondary$ = theme$.pipe(pluck('btnTextColorSecondary')),
          btnTextColorDisabled$ = theme$.pipe(pluck('btnTextColorDisabled')),
          btnActiveColor$ = this.type == "primary" ? btnColorPrimary$ : btnColorSecondary$,
          btnActiveTextColor$ = this.type == "primary" ? btnTextColorPrimary$ : btnTextColorSecondary$,
          btnHoverColor$ = btnActiveColor$.pipe(map(c => this.lightenColor(c, -10))),
          btnHoverTextColor$ = btnActiveTextColor$.pipe(map(c => this.lightenColor(c, -10)))


    this.backgroundColor$ = combineLatest([this.hover$, this.disabled$]).pipe(
      switchMap(([h, d]) => d ? btnColorDisabled$ : h ? btnHoverColor$ : btnActiveColor$)
    )
    this.textColor$ = combineLatest([this.hover$, this.disabled$]).pipe(
      switchMap(([h, d]) => d ? btnTextColorDisabled$ : h ? btnHoverTextColor$ : btnActiveTextColor$)
    )
  }

  ngOnInit() {
    this.subs.push(
      this.clickSubject.asObservable().pipe(
        switchMap(e => this.disabled$.pipe(
          take(1), 
          filter(d => !d), 
          mapTo(e)
        ))
      ).subscribe(e => this.clickEmiter.next(e))
    )
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  click(e: PointerEvent) {
    e.stopPropagation()
    this.clickSubject.next(e)
  }

  lightenColor(color: string, percent: number) {
    let num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = (num >> 8 & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;
    return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
  }

  private lightenDarkenColor(col: string, amount: number) {
    let usePound = false
  
    if (col[0] == "#") {
      col = col.slice(1)
      usePound = true
    }
 
    let num = parseInt(col, 16)
 
    let r = (num >> 16) + amount
 
    if (r > 255) r = 255
    else if  (r < 0) r = 0
 
    let b = ((num >> 8) & 0x00FF) + amount
 
    if (b > 255) b = 255
    else if  (b < 0) b = 0
 
    let g = (num & 0x0000FF) + amount
 
    if (g > 255) g = 255
    else if (g < 0) g = 0
 
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
  }
}