import { Injectable } from "@angular/core"
import { ComponentStore } from '@ngrx/component-store'
import { filter, map, tap, withLatestFrom } from 'rxjs/operators'
import { Exam, Question, AnswerOption, SessionExam } from "src/app/core"
import { StudentAnswer } from "src/app/core/services/exam.service"

export interface ExamState {
  loading: boolean
  completed: boolean
  exam: Exam
  sessionExam: SessionExam
  studentAnswers: StudentAnswer[]
  currentQuestionIndex: number
  questions: Question[]
}

/**
 * просто следит за текущим вопросом в экзамене и сохраняет ответы студента
 */
@Injectable()
export class ExamStateService extends ComponentStore<ExamState> {

  /**
   * Loading
   */
  loading$ = this.select(s => s.loading)
  setLoading = this.updater((s, loading: boolean) => ({ ...s, loading }))

  /**
   * Completed
   */
  completed$ = this.select(s => s.completed)
  setCompleted = this.updater((s, completed: boolean) => ({ ...s, completed }))

  /**
   * Exam
   */
  exam$ = this.select(s => s.exam)
  setExam = this.updater((s, exam: Exam) => ({ ...s, exam }))

  /**
   * Questions
   */
  questions$ = this.select(s => s.questions)
  questionsCount$ = this.select(this.questions$, questions => questions.length)
  setQuestions = this.updater((s, questions: Question[]) => ({ ...s, questions }))
  /**
   * ExamStudent
   */
  sessionExam$ = this.select(s => s.sessionExam)
  setSessionExam = this.updater((s, sessionExam: SessionExam) => ({ ...s, sessionExam }))

  /**
   * Current question
   */
  currentQuestionIndex$ = this.select(state => state.currentQuestionIndex)
  currentQuestion$ = this.select(
    this.questions$, this.currentQuestionIndex$,
    (questions, i) => questions[i]
  )
  isLastQuestion$ = this.select(
    this.questions$, this.currentQuestionIndex$,
    (questions, i) => questions.length - 1 == i
  )
  isFirstQuestion$ = this.select(this.exam$, this.currentQuestionIndex$,
    (exam, i) => exam ? i == 0 : false
  )
  setCurrentQuestionIndex = this.updater((state, currentQuestionIndex: number) => ({ 
    ...state, currentQuestionIndex 
  }))

  /**
   * Student Answers
   */
  studentAnswers$ = this.select(s => s.studentAnswers)

  resetExamState() {
    this.setState({
      loading: true,
      completed: false,
      exam: null,
      sessionExam: null,
      currentQuestionIndex: 0,
      studentAnswers: [],
      questions: []
    })
  }


  constructor() {
    super({
      loading: true,
      completed: false,
      exam: null,
      sessionExam: null,
      currentQuestionIndex: 0,
      studentAnswers: [],
      questions: []
    })
  }

  /**
   * Next()
   */
  readonly next = this.effect((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.isLastQuestion$),
      filter(([, isLast]) => !isLast),
      tap(() => {
        this.setCurrentQuestionIndex(this.get().currentQuestionIndex + 1)
      })
    )
  })

  /**
   * Prev()
   */
  readonly prev = this.effect((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.isFirstQuestion$),
      filter(([, isFirst]) => !isFirst),
      tap(() => {
        this.setCurrentQuestionIndex(this.get().currentQuestionIndex - 1)
      })
    )
  })
  
  confirmAnswer(answer: StudentAnswer) {
    const studentAnswers = [...this.get().studentAnswers, answer]
    this.setState(s => ({ ...s, studentAnswers }))
  }
}