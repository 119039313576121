import { Component, ChangeDetectionStrategy } from '@angular/core'
import { RegistrationStateStore } from '../registration-state.store'
import { RegistrationService, RegistrationParams } from 'src/app/core'
import { Router } from '@angular/router'
import { switchMap, take } from 'rxjs/operators'
import { AppStateStore } from 'src/app/app-state.store'
import { HttpErrorResponse } from '@angular/common/http'
import { zip } from 'rxjs';

@Component({
  selector: "registration-complete-component",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="page-container">
    <div class="page page--system-screen">
      <div class="page-content-wrap">
        <div class="page-content page-content--system-screen">
          <loader [visible]="true">Загрузка</loader>
        </div>
      </div>
    </div>
  </div>
  `
})
export class RegistrationCompleteComponent {
  programUuid$ = this.appStateStore.programUuid$
  registrationForm$ = this.appStateStore.select(
    this.appStateStore.externalId$,
    this.registrationStateStore.registrationParams$,
    (external_id, registration_form) => ({ ...registration_form, external_id })
  )
  externalParams$ = this.appStateStore.externalParams$
  registrationParams$ = this.appStateStore.select(
    this.programUuid$,
    this.registrationForm$,
    this.externalParams$,
    (program_uuid, registration_form, external_params) => ({ program_uuid, registration_form, external_params })
  )

  constructor(
    private registration: RegistrationService,
    private router: Router,
    private registrationStateStore: RegistrationStateStore,
    private appStateStore: AppStateStore
  ) {}

  ngOnInit() {
    zip(this.registrationParams$, this.appStateStore.accessToken$).pipe(
      take(1),
      switchMap(([params, access_token]) => this.registration.createSession(params, { access_token }))
    ).subscribe(session => {
      localStorage.setItem("session", session.uuid)
      this.appStateStore.setSession(session)
      this.router.navigate(["instructor"])
    }, err => {
      if (err instanceof HttpErrorResponse) {
        const { error } = err
        const message = error["error_description"] || "Неизвестная ошибка"
        this.registrationStateStore.setError({ message })
      }
    })
  }
}
