import { Expose } from 'class-transformer'

type FormControlType = "email"|"phone"|"telegram"|"fio"|"city"|"date"|"dict"

export class RegistrationFormControl {
  @Expose() name: string
  @Expose() type: FormControlType
  @Expose() label: string
  @Expose() value: string
  @Expose() required: boolean
  @Expose() disabled: boolean

  get validators(): string[] {
    let validators = []
    if (this.required) validators.push("required")
    if (this.type == "email") validators.push("email")
    return validators
  }
}
