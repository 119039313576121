import { Component, Input } from "@angular/core"
import { map, take } from 'rxjs/operators'
import { ProgramSection } from "src/app/core"
import { InstructorStateStore } from '../../instructor-state.store'
import { environment } from 'src/environments/environment'
import { VideoSectionStore } from "./video-section.store"
import { AppStateStore } from "src/app/app-state.store"


@Component({
  selector: "video-section-component",
  templateUrl: "./video-section.component.html",
  providers: [VideoSectionStore]
})
export class VideoSectionComponent {
  @Input() section: ProgramSection

  maxWatched$ = this.videoSectionStore.timeWatched$

  /**
   * Prev Hidden
   */
  prevHidden$ = this.instructorStateStore.select(
    this.instructorStateStore.isFirstSection$, 
    this.instructorStateStore.isLastSection$,
    (isFirst, isLast) => isFirst || isLast
  )

  /**
   * NextBtnCaption
   */
  nextBtnCaption$ = this.instructorStateStore.isLastSection$.pipe(
    map(isLast => isLast ? "Готово" : "Вперед")
  )

  /**
   * Prev / Next Disabled
   * кнопка активна если контент полностью просмотрен или можно пропускать видео
   * (fw || this.canSkipVideo)
   */
  prevDisabled$ = this.videoSectionStore.watched$.pipe(map(fw => !(fw || this.canSkipVideo)))
  nextDisabled$ = this.videoSectionStore.watched$.pipe(map(fw => !(fw || this.canSkipVideo)))


  constructor(
    private videoSectionStore: VideoSectionStore,
    private instructorStateStore: InstructorStateStore,
    private appStateStore: AppStateStore
  ) {}

  ngOnInit() {
    const id = this.section.contentId
    const contentKey = `video.${id}`

    this.appStateStore.session$.pipe(
      take(1),
      map(session => session.contentState),
      map(contentState => contentState[contentKey] || {}),
      map(({ t, watched }) => ({ t, watched }))
    ).subscribe(videoState => {
      const t = videoState.t || 0
      const watched = videoState.watched || false
      return this.videoSectionStore.setState({ id, t, watched })
    })
  }

  onVideoError(err: Error) {
    console.log("Ошибка загрузки видео", err)
    this.instructorStateStore.setError({
      message: "Ошибка загрузки видео"
    })
  }

  onVideoPlaying() {
      // if (this.canSkipVideo) {
      //   setTimeout(() => {
      //     this.videoSectionStore.updateWatched(true)
      //   }, 2000)
      // }
  }

  onVideoEnded() {
    this.videoSectionStore.updateWatched(true)
  }

  onMaxWatched(time: number) {
    this.videoSectionStore.updateTimeWatched(time)
  }

  get canSkipVideo(): boolean {
    return environment.canSkipVideo
  }

  get videoUrl(): string {
    return this.section.content["videoUrl"]
  }

  next() {
    const transition = this.section.defaultTransition
    if (transition) this.instructorStateStore.next(transition.nextUuid)
  }

  prev() {
    this.instructorStateStore.prev()
  }
}