<div class="form-group">
  <label for="control-{{config.id}}">
    {{ config.label }}<span *ngIf="config.required" class="required">&nbsp;*</span>
  </label>

  <div class="file" *ngFor="
    let file of selectedFiles$ | async;
    let i = index
  ">
    <div class="file-name" [title]="file.name">{{ file.name }}</div>

    <div class="file-size">({{ file.size | megabite }})</div>
    
    <div class="close" (click)="remove(i)">
      <img class="image16" src="/assets/remove-file.svg" alt="Загрузить">
    </div>
  </div>

  <div class="file-select-container">
    <div class="file-select-wrap">
      <input type="file"
      id="file-{{ config.id }}"
      class="inputfile"
      (change)="handleFilesChange($event.target.files)"
      [multiple]="multiFileSelect"
      />

      <label class="btn btn-default-fr btn-file-select" for="file-{{ config.id }}">
        <img class="image16" src="/assets/upload.svg" alt="Выбрать">

        {{ (selectedFiles$ | async).length == 0 ? 'Выбрать' : multiFileSelect ? 'Добавить' : 'Заменить'}}
      </label>

      <div class="help-block">{{ this.helpText }}</div>
    </div>
  </div>
</div>