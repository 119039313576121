<registration-error
*ngIf="error$ | async as error; else noError"
[error]="error"
></registration-error>

<ng-template #noError>
  <registration-complete-component
  *ngIf="completed$ | ngrxPush; else notCompleted"
  ></registration-complete-component>

  <ng-template #notCompleted>
    <ng-container *ngIf="confirmation$ | ngrxPush as confirmation; else registrtionForm">
      <phone-confirmation-component
      [confirmation]="confirmation"
      *ngIf="isPhoneConfirmation(confirmation)"
      ></phone-confirmation-component>  

      <email-confirmation-component 
      [confirmation]="confirmation"
      *ngIf="isEmailConfirmation(confirmation)"
      ></email-confirmation-component>
    </ng-container>

    <ng-template #registrtionForm>
      <ng-container *ngIf="registrationFormConfig$ | ngrxPush as registrationFormConfig">
        <registrtion-form-component
        [registrationFormConfig]="registrationFormConfig"
        ></registrtion-form-component>
      </ng-container>
    </ng-template>
  </ng-template>
</ng-template>