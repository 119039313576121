<div class="page-container">
  <div class="page">
    <div class="page-content-wrap">
      <div class="page-content">
        <div class="page-header">
          <h2 class="page-title">{{ formTitle }}</h2>
        </div>

        <div class="required-fields-disclaimer">
          Пожалуйста,&nbsp;заполните все&nbsp;обязательные&nbsp;поля.
        </div>

        <form *ngIf="form" [formGroup]="form" class="registration-form">
          <ng-container *ngFor="let controlConfig of registrationFormConfig.controls">
            <ng-container [ngSwitch]="controlConfig.type">
              <div class="form-group" *ngSwitchCase="'email'">
                <label for="">
                  {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  [formControl]="form.controls[controlConfig.name]"
                  [attr.disabled]="controlConfig.disabled"
                />
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'phone'">
                <registration-control-mobile-phone
                  [controlConfig]="controlConfig"
                  [formControl]="form.controls[controlConfig.name]"
                  [disabled]="controlConfig.disabled"
                ></registration-control-mobile-phone>
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'telegram'">
                <label for="">
                  {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  [formControl]="form.controls[controlConfig.name]"
                  [attr.disabled]="controlConfig.disabled"
                />
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'fio'">
                <label for="">
                  {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  mask="L*"
                  [dropSpecialCharacters]="false"
                  [formControl]="form.controls[controlConfig.name]"
                  [attr.disabled]="controlConfig.disabled"
                >
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'city'">
                <registration-control-city
                  [controlConfig]="controlConfig"
                  [formControl]="form.controls[controlConfig.name]"
                  [disabled]="controlConfig.disabled"
                ></registration-control-city>
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'string'">
                <label for="">
                  {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
                </label>
                <input
                  class="form-control"
                  type="text"
                  [formControl]="form.controls[controlConfig.name]"
                  [attr.disabled]="controlConfig.disabled"
                />
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'number'">
                <label for="">
                  {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
                </label>
                <input
                  class="form-control"
                  type="number"
                  [formControl]="form.controls[controlConfig.name]"
                  [attr.disabled]="controlConfig.disabled"
                />
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'date'">
                <registration-control-date
                  [controlConfig]="controlConfig"
                  [formControl]="form.controls[controlConfig.name]"
                  [disabled]="controlConfig.disabled"
                ></registration-control-date>
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>

              <div class="form-group" *ngSwitchCase="'dictionary'">
                <registration-control-dict
                  [controlConfig]="controlConfig"
                  [formControl]="form.controls[controlConfig.name]"
                  [readonly]="controlConfig.disabled"
                ></registration-control-dict>
                <show-errors [control]="form.controls[controlConfig.name]"></show-errors>
              </div>
            </ng-container>
          </ng-container>
        </form>

        <div class="registration-hints-container">
          <div class="registration-hints">
            <p class="registration-hint">
              Поля, отмеченные&nbsp;<span class="required">*</span>, обязательны для заполнения
            </p>

            <p class="registration-hint">
              Нажимая кнопку &laquo;Продолжить&raquo; вы&nbsp;соглашаетесь на&nbsp;обработку <a href="https://yandex.ru/legal/confidential" _target="blank">персональных данных</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- navigation -->
    <div class="registration-navigation">
      <navigation-button
      type="primary"
      (click)="next()"
      [disabled]="!(valid$ | ngrxPush)"
      [full]="true"
      >
        {{ confirmBtnCaption }}
      </navigation-button>
    </div>
  </div>
</div>
