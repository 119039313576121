import { 
  Component, Input, ElementRef, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit
} from "@angular/core"
import { Subscription, Observable, fromEvent } from "rxjs"
import { tap, last, map } from "rxjs/operators"
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'

// TODO: сделать повторные попытки загрузить видео
const HIDE_CONTROLS_TIMEOUT = 4 * 1000 // 4 секунды
const FORWARD_STEP = 15
const BACKWARD_STEP = 15

@Component({
  selector: "video-player",
  template: `
    <!-- loader -->
    <loader [visible]="loading">
      {{ loaderTitle }}
    </loader>

    <video #video class="content-video" 
    autoplay
    playsinline
    webkit-playsinline
    controlslist="nodownload"
    [translate]="'web_instructor.errors.video_not_supported'"
    ></video>

    <div class="video-player" [ngClass]="{
      'video-player--visible': controlsVisible
    }">
      <div class="video-progress">
        <div class="time-progress">
          {{ timeProgressLabel }}
        </div>

        <div class="progress-bar-wrap">
          <div class="progress-bar">
            <div class="progress-bar-inner progress-bar-inner-current" 
            [style.width]="videoProgress * 100 + '%'"></div>

            <div class="progress-bar-inner progress-bar-inner-nax-watched" 
            [style.width]="videoMaxProgress * 100 + '%'"></div>
          </div>
        </div>
      </div>


      <div class="video-controls">
        <div class="video-control-btn video-control-btn-back"
        [style.visibility]="video.currentTime < 1 ? 'hidden' : 'visible'"
        (click)="videoBackward()"></div>

        <div class="video-control-btn video-control-btn-play" *ngIf="video.paused" 
        (click)="togglePlay()"></div>
        <div class="video-control-btn video-control-btn-pause" *ngIf="!video.paused" 
        (click)="togglePlay()"></div>

        <div class="video-control-btn video-control-btn-forward"
        [style.visibility]="video.currentTime < maxWatched ? 'visible' : 'hidden'" 
        (click)="videoForward()"></div>
      </div>
    </div>
  `
})
export class VideoPlayerComponent implements OnDestroy {
  @Input() videoUrl: string
  @Input() maxWatched: number = 0

  @Output('error') onErrorEmitter = new EventEmitter<Error>()
  @Output('playing') onPlayingEmiter = new EventEmitter<boolean>()
  @Output('ended') onEndedEmitter = new EventEmitter<boolean>()
  @Output('maxWatched') onMaxWatchedEmiter = new EventEmitter<number>()

  @ViewChild('video', { static: true }) videoElRef: ElementRef
  
  loaderTitle = "Загрузка..."
  loading = true
  controlsVisible: boolean = false
  hideControlsTimeout: number
  private preloadSubscription$: Subscription
  private subs: Subscription[] = []

  constructor (
    public http: HttpClient
  ) {}

  ngOnInit() {
    this.controlsVisible = true
    this.addSource(this.videoUrl)

    this.subs.push(
      fromEvent(this.video, "canplay").subscribe(() => this.loading = false),
      fromEvent(this.video, "click").subscribe(() => this.toggleShowControls()),
      fromEvent(this.video, "timeupdate").subscribe(() => {
        this.maxWatched = Math.max(this.video.currentTime, this.maxWatched)
        this.onMaxWatchedEmiter.emit(this.maxWatched)
      }),
      fromEvent(this.video, "playing").subscribe(() => {
        this.showControls()
        this.onPlayingEmiter.emit(true)
      }),
      fromEvent(this.video, "pause").subscribe(() => {
        this.clearHideControlsTimeout()
        this.controlsVisible = true
      }),
      fromEvent(this.video, "ended").subscribe(() => this.onEndedEmitter.emit(true)),
      fromEvent(this.video, "error").subscribe((e: unknown) => this.onErrorEmitter.emit(e as Error))
    )

    if (this.maxWatched > 0) {
      this.video.currentTime = this.maxWatched
    }
  }

  ngOnDestroy(): void {
    this.cancelPreload()
    this.maxWatched = 0
    this.subs.forEach(s => s.unsubscribe())
  }

  preloadVideo(url: string): Observable<Object> {
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: "blob"
    })

    return this.http.request(req).pipe(
      tap(event => {
        if (event.type == HttpEventType.DownloadProgress) {
          let progres = Math.round(event.loaded / event.total * 100) + " %"
          this.loaderTitle = `Загрузка ${progres}`//this.translate.instant("web_instructor.loader_video_progress", { progres })
        }
      }),
      last(),
      map(resp => {
        resp = <HttpResponse<Blob>>resp
        return { blobUrl: URL.createObjectURL(resp.body) }
      })
    )
  }

  cancelPreload() {
    if (this.preloadSubscription$) { this.preloadSubscription$.unsubscribe() }
  }

  get video(): HTMLVideoElement {
    return this.videoElRef && this.videoElRef.nativeElement
  }

  videoForward(): void {
    const time = Math.min(this.video.currentTime + FORWARD_STEP, this.maxWatched)
    this.video.currentTime = time
  }

  videoBackward(): void {
    const time = Math.max(this.video.currentTime - BACKWARD_STEP, 0)
    this.video.currentTime = time
  }

  togglePlay(): void {
    if (this.video.paused) {
      this.video.play()
    } else {
      this.video.pause()
    }
  }

  toggleShowControls(): void {
    if (this.controlsVisible) {
      this.hideControls()
    } else {
      this.showControls()
    }
  }

  showControls(): void {
    this.controlsVisible = true
    this.clearHideControlsTimeout()

    this.hideControlsTimeout = window.setTimeout(() => {
      this.controlsVisible = false
      this.clearHideControlsTimeout()
    }, HIDE_CONTROLS_TIMEOUT)
  }

  hideControls(): void {
    this.controlsVisible = false
    this.clearHideControlsTimeout()
  }

  clearHideControlsTimeout(): void {
    if (this.hideControlsTimeout) {
      window.clearTimeout(this.hideControlsTimeout)
      this.hideControlsTimeout = null
    }
  }

  secondsToTimeStr(seconds: number): string {
    if (!seconds) {
      return "00:00"
    }

    let date = new Date(null)
    date.setSeconds(seconds)

    if (seconds < 60 * 60 - 1) {
      return date.toISOString().substr(14, 5)
    } 
    else if (seconds < 60 * 60 * 10 - 1) {
      return date.toISOString().substr(12, 7)
    }
    else {
      return date.toISOString().substr(11, 8)
    }
  }

  get timeProgressLabel(): string {
    return this.video ? 
      `${this.secondsToTimeStr(this.video.currentTime)} / ${this.secondsToTimeStr(this.video.duration)}` :
      '00:00 / 00:00'
  }

  // 0 to 1
  get videoProgress(): number {
    return this.video && this.video.currentTime / this.video.duration
  }
  // 0 to 1
  get videoMaxProgress(): number {
    return this.video && this.maxWatched / this.video.duration
  }

  clearSources() {
    let sources = Array.from(this.video.querySelectorAll("source"))
    sources.forEach(s => this.video.removeChild(s))
    this.video.load()
  }

  addSource(sourceURL: string) {
    let source = document.createElement('source')
    source.type = "video/mp4"
    source.src = sourceURL
    this.video.appendChild(source)
    this.video.load()
  }
}