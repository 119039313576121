import {Component, OnInit} from '@angular/core'
import { ProgramService, SystemScreenSection, ThemeService } from '../core'
import { InstructorStateStore } from "./instructor-state.store"
import { AppStateStore } from '../app-state.store'
import { take, filter } from 'rxjs/operators'
import { Subscription, fromEvent } from 'rxjs'

@Component({
  templateUrl: "./instructor.component.html"
})
export class InstructorComponent implements OnInit {

  loading$ = this.instructorStateStore.loading$
  completed$ = this.instructorStateStore.completed$
  currentSection$ = this.instructorStateStore.currentSection$
  error$ = this.instructorStateStore.error$
  sessionChange$ = fromEvent(window, "storage").pipe(
    filter((e: StorageEvent) => e.key == "instructor"),
    take(1)
  )

  private subs: Subscription[] = []

  constructor(
    private instructorStateStore: InstructorStateStore,
    private appStateStore: AppStateStore,
    private programService: ProgramService,
    private themeService: ThemeService
  ) {}
  

  async ngOnInit() {
    try {
      const session = await this.appStateStore.session$.pipe(take(1)).toPromise()
      const program = await this.programService.getProgram(session.programUuid).pipe(take(1)).toPromise()
      await this.themeService.loadCustomTheme(program.uuid)
  
      console.log("program:", program)
      console.log("session:", session)
      this.instructorStateStore.setProgram(program)
      /**
       * загружаем историю из localstorage
       */
      const { transitionsHistory } = session
      if (transitionsHistory.length != 0) {
        this.instructorStateStore.setHistory(transitionsHistory)
      }
      else {
        const registrationSection = program.sections.find(s => s.content instanceof SystemScreenSection && s.content.kind == "registration")
        if (registrationSection) this.instructorStateStore.next(registrationSection.uuid)
      }
      this.instructorStateStore.setLoading(false)
    } catch {
      return this.instructorStateStore.setError({
        message: "Не получилось начать обучение"
      })
    }

    this.subs.push(
      this.sessionChange$.subscribe(() => {
        this.instructorStateStore.setError({
          message: "Обучение было открыто в другом окне."
        })
      })
    )
    // проставляем новое значение в локалсторадж
    localStorage.setItem("instructor", Date.now().toString() + "_" + Math.round((Math.random() * 1000000)).toString())
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  next() {
    // this.instructorStateStore.next()
  }

  prev() {
    this.instructorStateStore.prev()
  }
}
