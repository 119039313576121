import { Directive, ElementRef, HostListener } from '@angular/core'
import { fromEvent, Subject, Subscription } from 'rxjs'
import { map, filter, auditTime, debounceTime } from 'rxjs/operators'


@Directive({
  selector: '[appIframeResizer]'
})
export class IFrameResizerDirective {
  widthBreakPoint = 800
  private resizeStream = new Subject<UIEvent>()
  private subs: Subscription[] = []

  private updateIframeSizeStream = fromEvent(window, "message").pipe(
    filter((e: MessageEvent) => {
      const data = e.data
      return typeof data == "object" && data["action"] == "updateIframeSize"
    }),
    map((e: MessageEvent) => e.data["payload"]["height"] as number)
  )

  get minHeight(): number {
    // паддинги сверху и снизу (на десктопе)
    const pageContainer = document.querySelector(".page-container"),
          paddingTop = parseInt(getComputedStyle(pageContainer, null).getPropertyValue('padding-top')),
          paddingBottom = parseInt(getComputedStyle(pageContainer, null).getPropertyValue('padding-bottom'))
    // дефолтная навигация
    const registrationNavigation = document.querySelector(".registration-navigation") as HTMLDivElement,
          registrationNavigationHeight = registrationNavigation ? registrationNavigation.offsetHeight : 0
    // ширина окна
    const currentWidth = window.innerWidth
    // высота окна
    const documentHeight = window.innerHeight

    return (
      this.widthBreakPoint >= currentWidth 
        ? documentHeight 
        : documentHeight - paddingTop - paddingBottom
      ) - registrationNavigationHeight
  }

  constructor(
    public element: ElementRef
  ) {}

  ngOnInit() {
    const iframe = this.element.nativeElement as HTMLIFrameElement

    this.subs.push(
      this.resizeStream.pipe(
        // игнорируем первый ресайз
        auditTime(100),
        debounceTime(200)
      ).subscribe(() => {
        iframe.contentWindow.postMessage("resize", "*")
      }),

      this.updateIframeSizeStream.subscribe(h => {
        iframe.style.minHeight = this.minHeight + "px"
        iframe.style.height = h + "px"
      })
    )
  }

  @HostListener('window:resize', ['$event']) 
  onResize(e: UIEvent) {
    this.resizeStream.next(e)
  }
}
