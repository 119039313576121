import { Injectable } from "@angular/core"
import { Interactive, InteractiveItem } from 'src/app/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class InteractiveStateService {
  interactive: Interactive

  private readonly _currentItem = new BehaviorSubject<InteractiveItem>(null)
  readonly currentItem$ = this._currentItem.asObservable()
  get currentItem() {
    return this._currentItem.getValue()
  }
  set currentItem(item: InteractiveItem) {
    this._currentItem.next(item)
  }

  private readonly _completed = new BehaviorSubject<boolean>(false)
  readonly completed$ = this._completed.asObservable()

  init(interactive: Interactive) {
    this.interactive = interactive
    const currentItemId = interactive.currentItemId
    const currentItem = currentItemId && this.interactive.items.find(i => i.id == currentItemId) || null
    this.currentItem = currentItem || this.interactive.items[0]
  }

  setCompleted() {
    this._completed.next(true)
    this._completed.complete()
  }
}