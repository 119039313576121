import { Component, Input, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl } from '@angular/forms'
import { Subscription, Subject } from 'rxjs'
import { KioskFormControl } from 'src/app/core'
import { shareReplay } from 'rxjs/operators'

@Component({
  selector: "single-select-control",
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SingleSelectControlComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SingleSelectControlComponent),
    multi: true
  }],
  templateUrl: "./single-select-control.component.html",
  styleUrls: ["./single-select-control.component.sass"]
})
export class SingleSelectControlComponent implements ControlValueAccessor {
  @Input() config: KioskFormControl

  onTouched: Function = () => {}

  private selectedValueSubject = new Subject<number>()
  readonly selectedValue$ = this.selectedValueSubject.asObservable().pipe(shareReplay(1))

  private subs: Subscription[] = []

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  registerOnChange(onChange: any) {
    this.subs.push(this.selectedValue$.subscribe(onChange))
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched
  }

  writeValue(value: number) {
    if (value) {
      this.selectedValueSubject.next(value)
    }
  }
  
  validate(c: AbstractControl) {
    const value = c.value
    let errors = {}

    if (this.config.required) {
      if (!value) {
        const required = {
          message: "Обязательное поле"
        }
        errors = { ...errors, required }
      }
    }

    return Object.keys(errors).length == 0 ? null : errors
  }

  toggleRadioButton(value: number) {
    this.selectedValueSubject.next(value)
  }
}