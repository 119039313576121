import { Expose } from 'class-transformer'

export class TransitionTarget {
  @Expose() id: number
  @Expose() type: string
  @Expose({ name: "interactive_content_item_transition_id" }) interactiveContentItemTransitionId: number
  @Expose({ name: "target_interactive_content_item_id" }) targetInteractiveContentItemId: number

  get isContentItem(): boolean {
    return this.type == 'InteractiveContentItemTransitionTarget::ContentItem';
  }

  get isInteractiveExit(): boolean {
    return this.type == 'InteractiveContentItemTransitionTarget::InteractiveExit';
  }
}
