<div class="page-container" *ngIf="loading; else alreadyLoaded">
  <div class="page page--interactive">
    <div class="page-content-wrap">
      <div class="page-content page-content--system-screen">
        <loader [visible]="loading">{{ loaderText }}</loader>
      </div>
    </div>
  </div>
</div>

<ng-template #alreadyLoaded>
  <ng-container *ngIf="interactive">
    <ng-container *ngFor="
      let item of interactiveItems; 
      trackBy: trackByFn;
    ">
      <interactive-item-image
      [ngStyle]="itemStyle"
      [style.zIndex]="item.id == currentItemId ? '10' : '0'"
      [interactiveItem]="item" 
      *ngIf="item.kind == 'image' && !loading && displayItemsIds.indexOf(item.id) != -1">
      </interactive-item-image>
    
      <interactive-item-video 
      [ngStyle]="itemStyle"
      [style.zIndex]="item.id == currentItemId ? '10' : '0'"
      [interactiveItem]="item" 
      *ngIf="item.kind == 'video' && !loading && displayItemsIds.indexOf(item.id) != -1">
      </interactive-item-video>
    </ng-container>
  </ng-container>
</ng-template>