import { Component, OnDestroy, ElementRef, ViewChild, Input } from "@angular/core"
import { RegistrationService, Session, ProgramSection, StaticScreenSection } from "src/app/core"
import { from, fromEvent, Observable, Subscription, ReplaySubject, Subject, merge } from 'rxjs'
import { filter, map, switchMap, take, shareReplay } from "rxjs/operators"
import { InstructorStateStore } from '../../instructor-state.store'
import { ComponentStore } from '@ngrx/component-store'
import { IFrameObject } from 'iframe-resizer'
import { AppStateStore } from "src/app/app-state.store"

export interface StaticScreenState {
  loading: boolean
  contentFullViewed: boolean
}

@Component({
  selector: "static-screen-section-component",
  templateUrl: "./static-screen-section.component.html",
  styleUrls: ["./static-screen-section.component.sass"],
  providers: [ComponentStore]
})
export class StaticScreenSectionComponent implements OnDestroy {
  @Input() section: ProgramSection
  @ViewChild('iframe', { static: false }) private iframeElRef: ElementRef
  // loading
  loading$ = this.componentStore.select(s => s.loading)
  setLoading = this.componentStore.updater((s, loading: boolean) => ({ ...s, loading }))
  // iframe stuff
  private iframeSubject = new ReplaySubject<HTMLIFrameElement>()
  readonly iframe$ = this.iframeSubject.asObservable().pipe(shareReplay(1))
  // content
  contentFullViewed$ = this.componentStore.select(s => s.contentFullViewed)
  setContentFullViewed = this.componentStore
    .updater((s, contentFullViewed: boolean) => ({ ...s, contentFullViewed }))
  
  isLastSection$ = this.instructorStateStore.isLastSection$
  isFirstSection$ = this.instructorStateStore.isFirstSection$
  // navigation
  nextDisabled$ = this.componentStore.select(
    this.loading$, 
    this.contentFullViewed$, 
    (loading, contentFullViewed) => loading || !contentFullViewed
  )
  prevHidden$ = this.instructorStateStore.select(
    this.isFirstSection$, 
    this.isLastSection$,
    (isFirst, isLast) => isFirst || isLast
  )
  nextBtnCaption$ = this.isLastSection$.pipe(map(isLast => isLast ? "Готово" : "Вперед"))
  hideDefaultNavigation$ = this.instructorStateStore.select(
    this.isLastSection$, 
    this.instructorStateStore.currentSection$,
    (isLast, section) => isLast || section.defaultTransition == undefined || this.fullScreen
  )

  private nextSubject = new Subject<string>()
  readonly next$ = this.nextSubject.asObservable()
  readonly messageNext$ = fromEvent(window, "message").pipe(
    filter((e: MessageEvent) => {
      const data = e.data
      return typeof data == "object" && data["role"] == "transition" && data["transitionId"]
    }),
    map(e => {
      const data = e.data
      const buttonTransition = data["transitionId"]
      const transition = this.section.findTransitionByParams({ buttonTransition })
      if (transition) {
        return transition.nextUuid
      } else {
        console.warn("can not find transition for button", data["transitionId"], data["transitionName"])
        return null
      }
    })
  )
  readonly nextSection$ = merge(this.next$, this.messageNext$).pipe(
    filter(uuid => !!uuid),
    switchMap(uuid => this.submitForms().pipe(map(submitted => ({ uuid, submitted })))),
    filter(({ uuid, submitted }) => !!submitted)
  )
  readonly finishSession$ = this.appStateStore.session$.pipe(
    switchMap(s => this.registrationService.finishSession(s.uuid)),
    switchMap(session => this.instructorStateStore.program$.pipe(map(program => ({ session, program }))))
  )
  
  private subs: Subscription[] = []

  constructor (
    private instructorStateStore: InstructorStateStore,
    private componentStore: ComponentStore<StaticScreenState>,
    private appStateStore: AppStateStore,
    private registrationService: RegistrationService
  ) {
    this.componentStore.setState({
      loading: true,
      contentFullViewed: true
    });
  }

  async ngOnInit() {
    let session = await this.appStateStore.session$.pipe(take(1)).toPromise()
    const program = await this.instructorStateStore.program$.pipe(take(1)).toPromise()
    const isLastSection = await this.isLastSection$.pipe(take(1)).toPromise()
    // Завершаем обучение, если нужно
    if (
      this.section.completeEducation && 
      !session.isEducationCompleted &&
      ! await this.completeEducation(session.uuid)
    ) {
      return this.instructorStateStore.setError({
        message: "Не получилось завершить обучение"
      })
    }
    // Завершаем сессию на последнем слайде
    if (isLastSection) {
      if (await this.finishSession(session.uuid)) {
        localStorage.removeItem("session")
        localStorage.removeItem("programUuid")
        localStorage.removeItem("externalId")
        const redirectUrlString = program.redirectUrl
        if (redirectUrlString) {
          return this.redirect(session, redirectUrlString)
        }
      } else {
        return this.instructorStateStore.setError({
          message: "Не получилось завершить обучение"
        })
      }
    }
    // Загрузка Iframe завершена
    const iframe = await this.iframe$.pipe(take(1)).toPromise()
    const iframeResizer = iframe["iFrameResizer"] as IFrameObject
    if (iframeResizer && !this.fullScreen) iframeResizer.resize()
    this.subs.push(
      // Переключение на секцию
      this.nextSection$.subscribe(({ uuid }) => this.instructorStateStore.next(uuid))
    )
    this.setLoading(false)
  }

  ngAfterViewInit() {
    const iframeEl = this.iframeElRef.nativeElement as HTMLIFrameElement
    // wait iframe to be loaded before emit it
    iframeEl.addEventListener("load", () => {
      this.iframeSubject.next(iframeEl)
    })
    iframeEl.srcdoc = this.section.content["content"]
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  private async completeEducation(uuid: string): Promise<boolean> {
    try {
      await this.registrationService.completeEducation(uuid).pipe(take(1)).toPromise()
      return true
    } catch {
      return false
    }
  }

  private async finishSession(uuid: string): Promise<boolean> {
    try {
      await this.registrationService.finishSession(uuid).pipe(take(1)).toPromise()
      return true
    } catch {
      return false
    }
  }

  private submitForms(): Observable<boolean> {
    const promise = new Promise<boolean>((res, rej) => {
      const iframeEl = this.iframeElRef.nativeElement as HTMLIFrameElement
      const messageHandler = (e: MessageEvent) => {
        const data: { 
          result: boolean
          type: string
        } = e.data
        if (typeof data == "object" && data.type == "submitForms") {
          clearTimeout(timeout)
          res(data.result)
        }
      }
      const timeout = setTimeout(() => {
        window.removeEventListener("message", messageHandler)
        res(false)
      }, 1000)
      window.addEventListener("message", messageHandler, { once: true })
      iframeEl.contentWindow.postMessage("submitForms", "*")
    })
    return from(promise).pipe(take(1))
  }

  next() {
    const transition = this.section.defaultTransition
    if (transition) {
      this.nextSubject.next(transition.nextUuid)
    } else {
      this.nextSubject.next(null)
    }
  }

  prev() {
    this.instructorStateStore.prev()
  }

  private redirect(session: Session, redirectUrlString: string) {
    const redirectUrl = new URL(redirectUrlString)
    for (const key of Object.keys(session.externalParams)) {
      const value = session.externalParams[key]
      redirectUrl.searchParams.set(key, value)
    }
    if (session.externalId) {
      redirectUrl.searchParams.set("externalId", session.externalId)
    }
    redirectUrl.searchParams.set("sessionUuid", session.uuid)

    window.location.href = redirectUrl.href
  }

  get fullScreen(): boolean {
    const ss = this.section.content as StaticScreenSection
    return ss.fullScreen
  }
}