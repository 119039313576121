import { Component, Input } from "@angular/core"
import { FormArray, FormBuilder, FormGroup } from "@angular/forms"
import { KioskForm, KioskFormService, ProgramSection } from "../../../core"
import { InstructorStateStore } from "../../instructor-state.store"

interface FormAnswers {
  [key:string]: string|File[]|number
}

@Component({
  selector: "form-section-component",
  templateUrl: "./form-section.component.html",
  styleUrls: ["./form-section.component.sass"]
})
export class FormSectionComponent {
  @Input() section: ProgramSection
  kioskForm: KioskForm
  loading: boolean
  form: FormGroup

  constructor(
    private fb: FormBuilder,
    private instructorStateStore: InstructorStateStore,
    private formService: KioskFormService
  ) {}

  ngOnInit() {
    this.loading = true
    const formUuid = this.section.content.uuid
    
    this.formService.getKioskForm(formUuid).subscribe(f => {
      this.form = this.fb.group(f.controls.reduce((memo, control) => {
        memo[control.id] = this.fb.control(null)
        return memo
      }, {}))

      this.kioskForm = f
      console.log("kioskForm:", this.kioskForm)
      this.loading = false
    })
  }

  get controlsArray(): FormArray {
    return this.form.get("formControls") as FormArray
  }

  submit() {
    this.loading = true
    const formUuid = this.section.content.uuid
    // debugger
    const answers = this.form.value
    this.formService.submit(formUuid, this.prepareForm(answers)).subscribe(
      resp => {
        if (resp.ok) {
          this.next()
        } else {
          this.loading = false
        }
      }, err => {
        this.loading = false
      }
    )
  }

  next() {
    const transition = this.section.defaultTransition
    if (transition) this.instructorStateStore.next(transition.nextUuid)
  }

  prev() {
    this.instructorStateStore.prev()
  }

  private prepareForm(answers: FormAnswers): FormData {
    const fd = new FormData()

    this.kioskForm.controls.forEach(formControl => {
      const name = formControl.id.toString()
      const value = answers[name]
      const type = formControl.controlType
      const key = `answers[${ name }]`

      if (type == "files") {
        const files = value as File[]
        files ? files.forEach(f => fd.append(`${ key }[]`, f, f.name)) : fd.append(key, null)
      } 
      else if (type == "longText") {
        value ? fd.append(key, (value as string)) : fd.append(key, null)
      } 
      else if (type == "singleSelect") {
        value ? fd.append(key, (value as number).toString()) : fd.append(key, null)
      } 
      else {
        throw new Error(`Unknown control type: ${ type }`)
      }
    })

    return fd
  }
}