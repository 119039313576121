import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { RegistrationStateStore } from '../registration-state.store';
import { FormControl } from '@angular/forms';
import { CustomValidators, PhoneConfirmationService } from 'src/app/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { PhoneConfirmation } from '../form/registration-form.component';

export const phoneConfirmationCountdown = 30 // sec.

@Component({
  selector: "phone-confirmation-component",
  templateUrl: "./phone-confirmation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneConfirmationComponent {
  @Input() confirmation: PhoneConfirmation
  confirmationCodeInput = new FormControl('', [
    CustomValidators.required("Введите код подтверждения")
  ])
  confirmationKey: string
  valid$ = this.confirmationCodeInput.statusChanges.pipe(
    map(status => status && status == "VALID")
  )
  loading$ = new BehaviorSubject(true)
  btnDisabled$ = this.valid$.pipe(map(valid => !valid))
  countDown$ = timer(0, 1000).pipe(
    map(i => phoneConfirmationCountdown - i),
    take(phoneConfirmationCountdown + 1)
  )

  constructor(
    private readonly registrationStateStore: RegistrationStateStore,
    private phoneConfirmationService: PhoneConfirmationService
  ) {}

  ngOnChanges() {
    this.loading$.next(true)
    this.createConfirmation(this.confirmation.phone)
      .subscribe({
        next: (key) => {
          this.confirmationKey = key
          this.loading$.next(false)
        }
      })
  }

  createConfirmation(phone: string): Observable<string> {
    console.log("PhoneConfirmationComponent -> createConfirmation", phone)
    return this.phoneConfirmationService.create(phone, "ru").pipe(tap(() => this.startNextSmsDelay()))
  }

  checkConfirmation(confirmation: PhoneConfirmation, code: string) {
    this.loading$.next(true)
    this.phoneConfirmationService.confirm(this.confirmationKey, code).subscribe(token => {
      console.log("token:", token)
      this.confirmationCodeInput.reset()
      this.registrationStateStore.completeConfirmation(confirmation, token)
    }, err => {
      if (err instanceof HttpErrorResponse && err.error && err.error.error && err.error.error == "invalid_code") {
        const message = err.error.error_description
        this.confirmationCodeInput.setErrors({
          ...this.confirmationCodeInput.errors, invalideCode: { message, code }
        })
      }
      this.loading$.next(false)
    })
  }

  private startNextSmsDelay() {
    this.countDown$ = timer(0, 1000).pipe(
      map(i => phoneConfirmationCountdown - i),
      take(phoneConfirmationCountdown + 1)
    )
  }

  resendSms(confirmation: PhoneConfirmation) {
    this.loading$.next(true)
    this.confirmationCodeInput.reset()
    this.createConfirmation(confirmation.phone).subscribe(key => {
      this.confirmationKey = key
      this.loading$.next(false)
    })
  }

  back() {
    this.registrationStateStore.setConfirmations([])
    this.registrationStateStore.setFormSubmitted(false)
  }
}