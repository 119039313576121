import { Expose, Type } from 'class-transformer'
import { Question } from "./question.model"
import { AnswerOption } from "./answer-option.model"

export class SelectQuestion extends Question {
  @Expose({ name: "correct_answer_value" }) correctAnswerValue: number
  @Expose({ name: "shuffle_answer_options" }) shuffleAnswerOptions: boolean

  @Expose({ name: "answer_options" })
  @Type(() => AnswerOption)
  answerOptions: AnswerOption[]
  questionType = "SelectQuestion"

  checkCorrect(answerValue: number) {
    return this.correctAnswerValue == answerValue
  }
}