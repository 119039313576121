import { Expose, Type } from 'class-transformer'
import { Question } from "./question.model"
import { AnswerOption } from './answer-option.model'

export class MultiselectQuestion extends Question {
  @Expose({ name: "correct_answer_value" }) correctAnswerValue: number[]
  @Expose({ name: "shuffle_answer_options" }) shuffleAnswerOptions: boolean

  @Expose({ name: "answer_options" })
  @Type(() => AnswerOption)
  answerOptions: AnswerOption[]
  questionType = "MultiselectQuestion"

  checkCorrect(answerValue: number[]) {
    return this.correctAnswerValue.length == answerValue.length && 
      this.intersect(this.correctAnswerValue, answerValue).length == this.correctAnswerValue.length
  }

  private intersect(a: number[], b: number[]): number[] {
    const setB = new Set(b)
    return [...new Set(a)].filter(x => setB.has(x))
  }
}