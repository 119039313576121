import { environment } from 'src/environments/environment'

export abstract class BaseApiService {
  private readonly host = environment.apiHost
  private readonly prefix = ["instructor_api", "v1"]

  buildUrl(segments: string[], params = {}): string {
    const url = new URL([this.host].concat(this.prefix).concat(segments).join("/"))

    for(let key in params) {
      url.searchParams.set(key, params[key])
    }

    return url.href
  }
}
