import { Component, Input, forwardRef, Optional, Self } from '@angular/core'
import { RegistrationFormControl, CustomValidators } from 'src/app/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl, NG_VALIDATORS, AbstractControl, Validators } from '@angular/forms'
import { combineLatest, Observable } from "rxjs"
import { map, tap, withLatestFrom } from 'rxjs/operators'

export interface PhoneConfig {
  value: number
  label: string
  mask: string
}

@Component({
  selector: "registration-control-mobile-phone",
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RegistrationControlMobilePhoneComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => RegistrationControlMobilePhoneComponent),
    multi: true
  }],
  template: `
    <label [for]="controlConfig.name">
      {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
    </label>

    <div class="mobile-phone-control">
      <ng-select
      class="mobile-phone-control-code"
      [items]="items"
      [clearable]="false"
      [searchable]="false"
      [formControl]="phoneSelectControl"
      (blur)="onSelectBlur()"
      ></ng-select>
        
      <input class="form-control mobile-phone-control-number" type="text"
      [formControl]="phoneNumberControl"
      (blur)="onInputBlur()"
      [mask]="mask"
      [placeholder]="mask"
      [showMaskTyped]="true"
      />
    </div>
  `,
})
export class RegistrationControlMobilePhoneComponent implements ControlValueAccessor {
  @Input() controlConfig: RegistrationFormControl
  disabled = false
  @Input() readonly = false
  private onChange = (value: any) => {}
  private onTouched = () => {}
  items: PhoneConfig[] = [
    { label: "+7", value: 7, mask: "(000) 000-00-00" },
    { label: "+62", value: 62, mask: "(0000) 00-00-000" },
  ]
  phoneSelectControl = new FormControl(null)
  phoneNumberControl = new FormControl(null)
  phoneConfig$: Observable<PhoneConfig> = this.phoneSelectControl.valueChanges.pipe(
    tap(pc => this.mask = pc.mask)
  )
  phoneNumber$: Observable<string> = this.phoneNumberControl.valueChanges
  mask: string
  minLength: number
  private _value: string
  valueSub = this.phoneNumber$.pipe(
    withLatestFrom(this.phoneConfig$),
    map(([number, phoneConfig]) => {
      if (number.length == 0) {
        return null
      }
      return phoneConfig.value.toString() + number
    })
  )
  .subscribe(value => {
    this.onChange(value)
  })

  validate(c: AbstractControl) {
    if (this.phoneNumberControl.errors) {
      const message = "Неверный формат"
      const format = { message }
      return { format }
    }
    else {
      return null
    }
  }

  ngOnDestroy() {
    this.valueSub.unsubscribe()
  }

  set value(value: string) {
    let phoneConfig = value ?
      this.items.find(pc => value.startsWith(pc.value.toString())) :
      null
    let phoneNumber = value && phoneConfig ?
      value.replace(phoneConfig.value.toString(), "") :
      ""

    if (phoneConfig == null) {
      phoneConfig = this.items[0]
    }

    this.phoneSelectControl.setValue(phoneConfig)
    this.phoneNumberControl.setValue(phoneNumber)
  }

  get value(): string {
    return this._value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn
  }

  onSelectBlur() {
    // this.onTouched()
  }

  onInputBlur() {
    this.onTouched()
  }

  writeValue(value: string) {
    this.value = value
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}