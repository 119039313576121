import { NgModule, Optional, SkipSelf } from '@angular/core'
import { ProgramService } from "./services/program.service"
import { RegistrationService } from './services/registration.service'
 
@NgModule({
  imports: [],
  providers: [
    ProgramService,
    RegistrationService
  ],
  declarations: []
})
export class CoreModule { 
  constructor(@Optional() @SkipSelf() core: CoreModule){
    if (core) {
      throw new Error("You should import core module only in the root module")
    }
  }
}