import {Expose, Type} from 'class-transformer'
import {TransitionTarget} from "./transition-target.model";

export class Transition {
  @Expose() id: number
  @Expose() name: string
  @Expose() gesture: string
  @Expose({ name: "start_time" }) startTime: number
  @Expose({ name: "end_time" }) endTime: number
  @Expose({ name: "left_x" }) leftX: number
  @Expose({ name: "left_y" }) leftY: number
  @Expose({ name: "right_x" }) rightX: number
  @Expose({ name: "right_y" }) rightY: number
  @Expose() timeout: number

  @Expose({ name: "target" })
  @Type(() => TransitionTarget)
  target: TransitionTarget
}
