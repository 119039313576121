import { Expose } from 'class-transformer'

export type SessionState = "started"|"education_completed"|"completed"|"canceled"

export interface RegInfo {
  [key:string]: { 
    type: string, 
    label: string, 
    value: any
  }
}

export class Session {
  @Expose() uuid: string
  @Expose() state: SessionState
  @Expose({ name: "external_id" }) externalId: string
  @Expose({ name: "program_uuid" }) programUuid: string
  @Expose() grade: number
  @Expose({ name: "reg_info" }) regInfo: RegInfo
  @Expose({ name: "external_params" }) externalParams: { [key:string]: any }
  @Expose({ name: "transitions_history" }) transitionsHistory: string[]
  @Expose({ name: "content_state" }) contentState: Object

  get isActive(): boolean {
    return ["started", "education_completed"].indexOf(this.state) != -1
  }

  get isEducationCompleted(): boolean {
    return this.state == "education_completed"
  }
}