import { Injectable } from "@angular/core"
import { Program, RegistrationForm, CustomTheme } from "../models"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { HttpClient } from '@angular/common/http'
import { plainToClass } from 'class-transformer'
import { BaseApiService } from './base-api.service'


export interface RegistrationDictOption {
  id: number
  label: string
}

export interface DictsOptionsIndex {
  [key:string]: RegistrationDictOption[]
}

export interface RegistrationConfig {
  registrationForm?: RegistrationForm
  dictsOptionsIndex?: DictsOptionsIndex
}

@Injectable({
  providedIn: "root"
})
export class ProgramService extends BaseApiService {
  constructor(private http: HttpClient) {
    super()
  }

  getProgram(uuid: string): Observable<Program> {
    const url = this.buildUrl(["programs", uuid])
    return this.http.get(url).pipe(
      map(resp => {
        return plainToClass(Program, resp["program"], {
          excludeExtraneousValues: true
        })
      })
    )
  }

  getRegistrationConfig(uuid: string, params = {}): Observable<RegistrationConfig> {
    const url = this.buildUrl(["programs", uuid, "registration"], params)
    return this.http.get(url).pipe(
      map(resp => {
        const registrationFormConfigJson = resp["registration_form_config"],
              dictionariesOptionsJson = resp["dictionaries_options"] as {
                key: string,
                items: RegistrationDictOption[]
              }[]

        if (Object.keys(registrationFormConfigJson).length == 0)
          return {}
        else
          return {
            registrationForm: plainToClass(RegistrationForm, registrationFormConfigJson, {
              excludeExtraneousValues: true
            }),
            dictsOptionsIndex: dictionariesOptionsJson.reduce((m, dict) => {
              m[dict.key] = dict.items
              return m
            }, {})
          }
      })
    )
  }

  getThemeConfig(uuid: string): Observable<CustomTheme> {
    const url = this.buildUrl(["programs", uuid, "theme"])
    return this.http.get(url).pipe(
      map(resp => {
        return plainToClass(CustomTheme, resp["theme"], {
          excludeExtraneousValues: true
        })
      })
    )
  }
}
