import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from "src/app/shared/shared.module"
import { RegistrationRoutingModule } from "./registration-routing.module"
import { RegistrationComponent } from "./registration.component"
import { RegistrationControlCityComponent } from "./controls/registration-control-city.component"
import { RegistrationControlMobilePhoneComponent } from "./controls/registration-control-mobile-phone.component"
import { NgSelectModule } from '@ng-select/ng-select'
// import { ShowErrorsComponent } from "./show-errors.component"
import { NgxMaskModule } from 'ngx-mask'
import { RegistrationStateStore } from "./registration-state.store"
import { RegistrationFormComponent } from "./form/registration-form.component"
import { PhoneConfirmationComponent } from "./phone-confirmation/phone-confirmation.component"
import { EmailConfirmationComponent } from "./email-confirmation/email-confirmation.component"
import { RegistrationCompleteComponent } from "./complete/registration-complete.component"
import { RegistrationControlDateComponent } from "./controls/registration-control-date.component"
import { RegistrationControlDictComponent } from "./controls/registration-control-dict.component"
import { RegistrationErrorComponent } from "./registration-error.component"

@NgModule({
  declarations: [
    RegistrationComponent,
    RegistrationFormComponent,
    PhoneConfirmationComponent,
    EmailConfirmationComponent,
    // ShowErrorsComponent,
    RegistrationControlCityComponent,
    RegistrationControlMobilePhoneComponent,
    RegistrationControlDateComponent,
    RegistrationControlDictComponent,
    RegistrationCompleteComponent,
    RegistrationErrorComponent
  ],
  imports: [
    NgxMaskModule.forChild(),
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    RegistrationRoutingModule
  ],
  providers: [
    RegistrationStateStore
  ]
})
export class RegistrationModule {}
