import { Type, Expose } from 'class-transformer'
import { RegistrationForm } from './registration'
import { ProgramSection } from './program-section.model'

export class Program {
  @Expose() id: number
  @Expose() uuid: string

  get url() {
    return ["program", this.uuid]
  }

  @Expose()
  @Type(() => RegistrationForm)
  registration: RegistrationForm

  @Expose({ name: "redirect_url" }) redirectUrl: string
  
  @Expose()
  @Type(() => ProgramSection)
  sections: ProgramSection[]
}