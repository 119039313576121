import { Component } from "@angular/core"
import { ThemeService } from "src/app/core/services"
import { pluck } from "rxjs/operators"

@Component({
  selector: "svg-exam-completed-success",
  templateUrl: "./svg-exam-completed-success.component.svg",
  styleUrls: ["../kiosk-svgs.sass"]
})
export class SvgExamCompletedSucces {
  mainColor$ = this.themeService.theme$.pipe(pluck("mainColor"))

  constructor(
    private themeService: ThemeService
  ) {}
}