import { Type, Expose } from 'class-transformer'
import { ProgramSectionTransitionCondition } from './program-section-transition-condition.model'

export type TransitionKind = "ProgramSectionTransition::DefaultTransition"|"ProgramSectionTransition::ConditionalTransition"

export class ProgramSectionTransition {
  @Expose() id: number
  @Expose() kind: TransitionKind
  @Expose() name: string
  @Expose() uuid: string
  @Expose({ name: "next_uuid" }) nextUuid: string
  @Expose({ name: "section_uuid" }) sectionUuid: string

  @Expose() 
  @Type(() => ProgramSectionTransitionCondition)
  conditions: ProgramSectionTransitionCondition[]

  acceptableFor(obj: Object) {
    return this.conditions.every(c => c.acceptableFor(obj))
  }
}