import { Expose, Type } from 'class-transformer'
import { ProgramSectionTransition } from '../program-section-transition.model'
import { RegistrationFormControl } from "./registration-form-control.model"

export class RegistrationForm {
  @Expose()
  @Type(() => RegistrationFormControl)
  controls: RegistrationFormControl[]

  @Expose() 
  @Type(() => ProgramSectionTransition)
  transitions: ProgramSectionTransition[]

  @Expose() title: string
  @Expose({ name: "confirm_button_text" }) confirmButtonText: string
}