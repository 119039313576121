import { Expose, Type } from 'class-transformer'
import { Content } from './content.model'
import { Exam } from './exam.model'
import { KioskForm } from './kiosk-form.model'
import { Interactive } from './interactive'
import { ProgramSectionTransition } from './program-section-transition.model'
import { Quiz } from './quiz.model'
import { StaticScreenSection } from './static-screen-section.model'
import { SystemScreenSection } from './system-screen-section.model'
import { Video } from './video.model'

export class ProgramSection {
  @Expose() uuid: string
  
  @Expose({ name: "content_id" }) contentId: number
  
  @Expose({ name: "content_type" }) contentType: string

  @Expose({ name: "complete_education" }) completeEducation: boolean
  
  /**
   * Content
   */
  @Expose()
  @Type(() => Content, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: SystemScreenSection, name: "system_screen" },
        { value: Exam, name: "exam" },
        { value: StaticScreenSection, name: "static_screen" },
        { value: Quiz, name: "lesson_quiz" },
        { value: Video, name: "video" },
        { value: Interactive, name: "interactive" },
        { value: KioskForm, name: "form" }
      ]
    }
  })
  content: Content

  /**
   * transitions
   */
   @Expose() 
   @Type(() => ProgramSectionTransition)
   transitions: ProgramSectionTransition[]
   
   /**
    * default transition
    */
   @Expose({ name: "default_transition" }) 
   @Type(() => ProgramSectionTransition)
   defaultTransition: ProgramSectionTransition
 
   /**
    * conditional transitions
    */
   @Expose({ name: "conditional_transitions" }) 
   @Type(() => ProgramSectionTransition)
   conditionalTransitions: ProgramSectionTransition[]
 
   findTransitionByParams(params: Object = {}): ProgramSectionTransition {
     return this.conditionalTransitions.find(t => t.acceptableFor(params))
   }
}