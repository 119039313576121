// простая ф-я для не глубокого сравнения объектов
const objectsAreEqual = (objA: Object, objB: Object): boolean => {
  const aProps = Object.getOwnPropertyNames(objA),
        bProps = Object.getOwnPropertyNames(objB)

  if (aProps.length != bProps.length) {
    return false
  }      
  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i]
    if (objA[propName] !== objB[propName]) {
      return false
    }
  }
  return true
}

export default objectsAreEqual