import { Component, Input } from '@angular/core'
import { ProgramSection } from 'src/app/core';
import { InstructorStateStore } from '../../instructor-state.store';
import { QuizSectionStore } from './quiz-section.store';

@Component({
  selector: "quiz-completed-failure-component",
  templateUrl: "./quiz-completed-failure.component.html"
})
export class QuizCompletedFailureComponent {
  @Input() section: ProgramSection

  constructor(
    private quizStore: QuizSectionStore,
    private instructorStateStore: InstructorStateStore
  ) {}

  correctAnswersCount$ = this.quizStore.score$
  
  questionsCount$ = this.quizStore.questionsCount$

  /**
   * Prev Hidden
   */
  prevHidden$ = this.instructorStateStore.select(
    this.instructorStateStore.isFirstSection$, 
    (instructorIsFirstSection) => instructorIsFirstSection
  )

  restart() {
    this.quizStore.restart()
  }

  prev() {
    this.instructorStateStore.prev()
  }
}