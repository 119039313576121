import { FormArray, FormControl, FormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  static required(message?: string): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const errors = {
        'required': {
          'message': message || "Обязательное поле"
        }
      };

      return this.isBlank(c.value) ? errors : null;
    }
  }

  static email(message?: string): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      const errors = Validators.email(c)

      return errors ? {
        'wrong-format': {
          'message': message || "Неверный формат"
        } 
      } : 
      null
    }
  }

  private static isBlank(value: any): boolean {
    if (this.isString(value)) {
      return value.trim().length == 0
    } 
    else if (this.isArray(value)) {
      return value.length == 0
    } 
    else {
      return !value && value != 0
    }
  }

  private static isString(value: any): boolean {
    return typeof value == 'string' || value instanceof String
  }

  private static isArray(value: any): boolean {
    return value instanceof Array
  }
}