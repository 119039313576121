import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { RegistrationStateStore, RegistrationFormParams } from '../registration-state.store';
import { RegistrationForm, RegistrationFormControl, CustomValidators, ProgramService } from 'src/app/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class PhoneConfirmation {
  id: number
  key: string
  token: string
  phone: string

  constructor(phone: string) {
    this.phone = phone
    this.id = Math.round(Math.random() * 1000000)
    this.key = null
    this.token = null
  }
}

export class EmailConfirmation {
  id: number
  key: string
  token: string
  email: string

  constructor(email: string) {
    this.email = email
    this.id = Math.round(Math.random() * 1000000)
    this.key = null
    this.token = null
  }
}

export type Confirmation = PhoneConfirmation | EmailConfirmation


@Component({
  selector: "registrtion-form-component",
  templateUrl: "./registration-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationFormComponent {
  @Input() registrationFormConfig: RegistrationForm

  form: FormGroup
  valid$: Observable<boolean>
  private validatorsMaping = {
    required: CustomValidators.required("Обязательное поле"),
    email: CustomValidators.email("Неверный формат для email")
  }

  constructor(
    private readonly registrationStateStore: RegistrationStateStore,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit() {
    const formGroup = this.registrationFormConfig.controls.reduce((memo, controlConfig) => {
      memo[controlConfig.name] = this.buildFormControl(controlConfig)
      return memo
    }, {})
    this.form = this.fb.group(formGroup)
    this.valid$ = this.form.statusChanges.pipe(map(s => s && s == "VALID"))
  }

  get formTitle(): string {
    return this.registrationFormConfig.title
  }

  get confirmBtnCaption(): string {
    return this.registrationFormConfig.confirmButtonText
  }

  next() {
    const registrationParams = this.form.value as RegistrationFormParams
    this.createConfirmations(registrationParams)
    this.registrationStateStore.setRegistrationParams(registrationParams)
    this.registrationStateStore.setFormSubmitted(true)
  }

  private createConfirmations(params: RegistrationFormParams) {
    let confirmations: Confirmation[] = []
    // if (params.phone) confirmations.push(new PhoneConfirmation(params.phone))
    // if (params.email) confirmations.push(new EmailConfirmation(params.email))

    if (confirmations.length > 0) this.registrationStateStore.setConfirmations(confirmations)
  }

  private buildFormControl(config: RegistrationFormControl): FormControl {
    let validators = config.validators.map(name => this.validatorsMaping[name])
    return this.fb.control(config.value || null, validators)
  }
}
