import { Component, Input } from "@angular/core"
import { AppStateStore } from 'src/app/app-state.store'
import { ProgramSection, SystemScreenSection } from "../../../core"
import { map, take } from "rxjs/operators"
import { InstructorStateStore } from '../../instructor-state.store'

/**
 * TODO: пока что единственный системный экран - это регистрация (точка входа)
 * доделать под разные типы системных экранов
 */
@Component({
  selector: "system-screen-section-component",
  templateUrl: "./system-screen-section.component.html",
})
export class SystemScreenSectionComponent {
  @Input() section: ProgramSection
  
  session$ = this.appStateStore.session$
  sessionData$ = this.session$.pipe(
    map(s => {
      return Object.keys(s.regInfo).reduce((m, k) => {
        m[k] = s.regInfo[k].value
        return m
      }, {})
    })
  )

  constructor (
    private appStateStore: AppStateStore,
    private instructorStateStore: InstructorStateStore
  ) {}

  ngOnInit() {
    this.sessionData$.pipe(take(1)).subscribe(sessionData => {
      setTimeout(() => {
        const transitions = this.section.transitions
        const transition = transitions
          .find(t => t.kind == "ProgramSectionTransition::ConditionalTransition" && t.acceptableFor(sessionData)) ||
          transitions.find(t => t.kind == "ProgramSectionTransition::DefaultTransition")
        
        if (transition) this.instructorStateStore.next(transition.nextUuid)
      })
    })
  }
}