import { Component, Input } from '@angular/core'
import { combineLatest } from 'rxjs';
import { ProgramSection } from 'src/app/core';
import { InstructorStateStore } from '../../instructor-state.store';
import { QuizSectionStore } from './quiz-section.store';

@Component({
  selector: "quiz-completed-success-component",
  templateUrl: "./quiz-completed-success.component.html"
})
export class QuizCompletedSuccessComponent {
  @Input() section: ProgramSection

  constructor(
    private quizStore: QuizSectionStore,
    private instructorStateStore: InstructorStateStore
  ) {}

  correctAnswersCount$ = this.quizStore.score$

  questionsCount$ = this.quizStore.questionsCount$

  /**
   * Prev Hidden
   */
  prevHidden$ = this.instructorStateStore.select(
    this.instructorStateStore.isFirstSection$, 
    (instructorIsFirstSection) => instructorIsFirstSection
  )

  prev() {
    this.instructorStateStore.prev()
  }

  next() {
    combineLatest([
      this.quizStore.score$,
      this.quizStore.quizSuccess$
    ]).subscribe(data => {
      const [correctAnswers, passed] = data
      const transition = this.section.findTransitionByParams({ correctAnswers, passed }) || 
        this.section.defaultTransition

      if (transition) {
        this.instructorStateStore.next(transition.nextUuid)
      }
    })
  }
}