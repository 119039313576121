import { Component, AfterViewInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core"
import { InteractiveItemImage, Interactive } from "src/app/core/models"
import { Subscription, Observable, fromEvent } from 'rxjs'
import { SafeUrl } from '@angular/platform-browser'
import { tap, last, switchMap, filter, map, mergeMap, shareReplay } from 'rxjs/operators'
import { InteractiveStateService } from './interactive-state.service'
import { FilesCacheService } from './files-cache.service'
import {TransitionTarget} from "../../../core/models/interactive/transition-target.model";

/**
 * если последний элемент интерактива картинка, интерактив закончится через 10 секунд
 */
const AUTOFINISH_TIMEOUT = 10

@Component({
  selector: "interactive-item-image",
  template: `
    <div class="page-container">
      <div class="page page--interactive">
        <div class="page-content-wrap">
          <div class="section section__interactive">
            <loader [visible]="loading">{{ loaderText }}</loader>

            <div class="img-container">
              <img #img class='image'
                [src]="imgSrc"
                (click)="onClick($event)"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class InteractiveItemImageComponent implements AfterViewInit, OnDestroy {
  @Input() private interactiveItem: InteractiveItemImage
  @ViewChild('img') private imgElRef: ElementRef
  private autoTransitionTimeout: number
  private autoFinishTransitionTimeout: number
  private dataLoaded: Observable<boolean>
  private currentItem: Observable<boolean>
  private dataLoadedSub: Subscription
  private currentItemSub: Subscription
  loading: boolean = true
  imgSrc: SafeUrl
  loaderText = "Загрузка..."

  constructor(
    private cache: FilesCacheService,
    private interactiveStateService: InteractiveStateService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.currentItem = this.interactiveStateService.currentItem$.pipe(
        filter(item => item.id == this.interactiveItem.id),
        map(() => true)
      )

      this.dataLoaded = this.cache.getFile(this.interactiveItem.itemUrl).pipe(
        tap(data => {
          this.loaderText = `Загрузка: ${ data.progress }%`
        }),
        last(),
        switchMap(data => {
          this.imgSrc = data.blob
          return fromEvent(this.image, "load").pipe(map(() => true))
        }),
        shareReplay(1)
      )

      this.dataLoadedSub = this.dataLoaded.subscribe(() => this.loading = false)

      this.currentItemSub = this.currentItem.pipe(
        mergeMap(() => this.dataLoaded)
      )
      .subscribe(() => {
        this.setAutoTransition()
        this.setAutoFinishTransition()
      })
    }, 0)
  }

  ngOnDestroy() {
    if (this.currentItemSub) this.currentItemSub.unsubscribe()
    if (this.dataLoadedSub) this.dataLoadedSub.unsubscribe()
    if (this.autoTransitionTimeout) clearTimeout(this.autoTransitionTimeout)
    if (this.autoFinishTransitionTimeout) clearTimeout(this.autoFinishTransitionTimeout)
  }

  get interactive(): Interactive {
    return this.interactiveStateService.interactive
  }

  get image(): HTMLImageElement {
    return this.imgElRef.nativeElement
  }

  private setAutoTransition() {
    if (this.interactiveItem.endTransition) {
      const transition = this.interactiveItem.endTransition,
            time = this.interactiveItem.endTransition.timeout * 1000
      if (transition) {
        this.autoTransitionTimeout = setTimeout(() => {
          this.setToTransitionTarget(transition.target)
        }, time)
      }
    }
  }

  private setAutoFinishTransition() {
    if (this.interactiveItem.isLast) {
      this.autoFinishTransitionTimeout = setTimeout(() => {
        this.finishInteractive()
      }, AUTOFINISH_TIMEOUT * 1000)
    }
  }

  finishInteractive() {
    this.interactiveStateService.setCompleted()
  }

  onClick(e: MouseEvent) {
    e.preventDefault()

    const clickCoords = this.getInteractiveClickCoords(e),
          mouseX = clickCoords.x,
          mouseY = clickCoords.y,
          transition = this.interactiveItem.buttonTransitions.find(t => {
            return mouseX >= t.leftX &&
              mouseX <= t.rightX &&
              mouseY >= t.leftY &&
              mouseY <= t.rightY
          })
    if (transition) {
      this.setToTransitionTarget(transition.target)
    }
  }

  getInteractiveClickCoords(e: MouseEvent): {
    x: number
    y: number
  } {
    let target = e.target,
        interactiveHeight: number,
        interactiveWidth: number,
        scale: number,
        viewportHeight: number,
        viewportWidth: number,
        viewportRatio: number

    if (target instanceof HTMLImageElement) {
      interactiveHeight = target.naturalHeight
      interactiveWidth = target.naturalWidth
      viewportHeight = target.height
      viewportWidth = target.width
    } else if (target instanceof HTMLVideoElement) {
      interactiveHeight = target.videoHeight
      interactiveWidth = target.videoWidth
      viewportHeight = target.offsetHeight
      viewportWidth = target.offsetWidth
    }

    viewportRatio = viewportWidth / viewportHeight

    let interactiveRatio = interactiveWidth / interactiveHeight
    scale = viewportRatio < interactiveRatio ? viewportWidth / interactiveWidth : viewportHeight / interactiveHeight

    let offsetX = (viewportWidth - interactiveWidth * scale) / 2,
        offsetY = (viewportHeight - interactiveHeight * scale) / 2

    const x = ((e.offsetX - offsetX) / (viewportWidth - 2 * offsetX)) * 100
    const y = ((e.offsetY - offsetY) / (viewportHeight - 2 * offsetY)) * 100

    return { x, y }
  }

  private setToTransitionTarget(target: TransitionTarget) {
    if (this.autoTransitionTimeout) clearTimeout(this.autoTransitionTimeout)
    if (this.autoFinishTransitionTimeout) clearTimeout(this.autoFinishTransitionTimeout)

    if (target.isContentItem) {
      const nextItem = this.interactive.items.find(i => i.id == target.targetInteractiveContentItemId)
      this.interactiveStateService.currentItem = nextItem
    } else if (target.isInteractiveExit) {
      this.finishInteractive()
    }
  }
}
