import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ExamStartResponse, Question, SessionExam } from '../models'
import { map } from 'rxjs/operators'
import { plainToClass } from 'class-transformer'
import { BaseApiService } from './base-api.service'


export interface SelectedOption {
  answer_option_id: number
}

export interface StudentAnswer {
  question_id: number
  answered_at: number
  custom_answer?: string
  selected_answer_options_attributes?: SelectedOption[]
}

@Injectable({
  providedIn: "root"
})
export class ExamService extends BaseApiService {
  constructor(
    private http: HttpClient
  ) {
    super()
  }

  start(sessionUuid: string, examUuid: string): Observable<ExamStartResponse> {
    const url = this.buildUrl(["exams", "start"])
    const params = {
      session_uuid: sessionUuid,
      exam_uuid: examUuid
    }

    return this.http.post(url, params).pipe(
      map(resp => plainToClass(ExamStartResponse, resp, { 
        excludeExtraneousValues: true 
      }))
    )
  }

  finish(
    sessionUuid: string, 
    sessionExamUuid: string, 
    answers: StudentAnswer[]
  ): Observable<SessionExam> {
    const url = this.buildUrl(["exams", "finish"])
    const params = {
      session_uuid: sessionUuid,
      session_exam_uuid: sessionExamUuid,
      answers
    }

    return this.http.patch(url, params).pipe(
      map(resp => {
        const json = resp["session_exam"]

        return plainToClass(SessionExam, json, { 
          excludeExtraneousValues: true 
        })
      })
    )
  }
}