import { NgModule } from '@angular/core'
import { ReactiveComponentModule } from '@ngrx/component'
import { SvgExamCompleted } from "./svg-exam-completed/svg-exam-completed.component"
import { SvgExamCompletedSucces } from "./svg-exam-completed-success/svg-exam-completed-success.component"
import { SvgExamCompletedFailure } from "./svg-exam-completed-failure/svg-exam-completed-failure.component"
import { SvgQuizCompletedSuccess } from "./svg-quiz-completed-success/svg-quiz-completed-success.component"
import { SvgQuizCompletedFailure } from "./svg-quiz-completed-failure/svg-quiz-completed-failure.component"
import { SvgFormSubmitted } from "./svg-form-submitted/svg-form-submitted.component"
import { SvgError } from "./svg-error/svg-error.component"

@NgModule({
  imports: [
    ReactiveComponentModule
  ],
  declarations: [
    SvgExamCompleted,
    SvgExamCompletedSucces,
    SvgExamCompletedFailure,

    SvgQuizCompletedSuccess,
    SvgQuizCompletedFailure,

    SvgFormSubmitted,

    SvgError
  ],
  exports: [
    SvgExamCompleted,
    SvgExamCompletedSucces,
    SvgExamCompletedFailure,

    SvgQuizCompletedSuccess,
    SvgQuizCompletedFailure,

    SvgFormSubmitted,

    SvgError
  ]
})
export class KioskSvgsModule {}