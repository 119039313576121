import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { LoaderComponent } from './loader.component'
import { KioskSvgsModule } from "./svgs/kiosk-svgs.module"
import { VideoPlayerComponent } from "./video-player.component"
import { ReactiveComponentModule } from '@ngrx/component'
import { IFrameResizerDirective } from "./directives"
import { ShowErrorsComponent } from './show-errors.component'
import { NavigationButton } from "./navigation-button.component"
import { MegaBitePipe } from "./pipes/megabite.pipe"

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ReactiveComponentModule,
    KioskSvgsModule
  ],
  declarations: [
    LoaderComponent,
    VideoPlayerComponent,
    IFrameResizerDirective,
    ShowErrorsComponent,
    NavigationButton,
    MegaBitePipe
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ReactiveComponentModule,
    KioskSvgsModule,
    LoaderComponent,
    VideoPlayerComponent,
    IFrameResizerDirective,
    ShowErrorsComponent,
    NavigationButton,
    MegaBitePipe
  ],
  providers: [
    MegaBitePipe
  ]
})
export class SharedModule {
}