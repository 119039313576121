import { Expose, Type } from "class-transformer"
import { Content } from "./content.model"
import { KioskFormControlOption } from "./kiosk-form-control-option.model"

export interface FilesControlCongig {
  multi_file_select: boolean
}

export class KioskFormControl extends Content {
  @Expose({ name: "label" }) label: string
  @Expose() required: boolean
  @Expose({ name: "control_type" }) controlType: string
  @Expose({ name: "config" }) params: Object|FilesControlCongig

  @Expose({ name: "control_options" })
  @Type(() => KioskFormControlOption)
  controlOptions: KioskFormControlOption[]
}