import { Expose } from 'class-transformer'

export type VariableType = "number"|"dict"|"string"

export type ConditionType = "equal"|"notEqual"|"more"|"less"|"moreOrEqual"|"lessOrEqual"|"between"|"notBetween"|"includedIn"|"notIncludedIn"|"boolEqual"

export type ConditionValue = number|[number, number]|number[]|string

export class ProgramSectionTransitionCondition {
  @Expose() id: number
  @Expose({ name: "condition_type" }) conditionType: ConditionType
  @Expose({ name: "condition_value" }) conditionValue: ConditionValue
  @Expose({ name: "variable_name" }) variableName: string
  @Expose({ name: "variable_type" }) variableType: VariableType

  acceptableFor(obj: Object) {
    if (this.variableName in obj) {
      const v = obj[this.variableName]
      
      if (this.conditionType == "equal" && typeof this.conditionValue == "number") return this.conditionValue == v
      if (this.conditionType == "notEqual" && typeof this.conditionValue == "number") return this.conditionValue != v
      if (this.conditionType == "more" && typeof this.conditionValue == "number") return v > this.conditionValue
      if (this.conditionType == "less" && typeof this.conditionValue == "number") return v < this.conditionValue
      if (this.conditionType == "moreOrEqual" && typeof this.conditionValue == "number") return v >= this.conditionValue
      if (this.conditionType == "lessOrEqual" && typeof this.conditionValue == "number") return v <= this.conditionValue
      if (this.conditionType == "between" && this.conditionValue instanceof Array) {
        const [from, to] = this.conditionValue
        return from <= v && v <= to
      }
      if (this.conditionType == "notBetween" && this.conditionValue instanceof Array) {
        const [from, to] = this.conditionValue
        return !(from <= v && v <= to)
      }
      if (this.conditionType == "includedIn" && this.conditionValue instanceof Array) return this.conditionValue.indexOf(v) != -1
      if (this.conditionType == "notIncludedIn" && this.conditionValue instanceof Array) return this.conditionValue.indexOf(v) == -1
      if (this.conditionType == "equal" && typeof this.conditionValue == "string") return this.conditionValue.trim().toLowerCase() == v.trim().toLowerCase()
      if (this.conditionType == "boolEqual" && typeof this.conditionValue == "boolean") return this.conditionValue === v

      return false
    }
    return false
  }
}