import { Injectable } from "@angular/core"
import { CanLoad, Router } from "@angular/router"
import { Observable } from "rxjs"
import { AppStateStore } from '../app-state.store'
import { map, take } from 'rxjs/operators'

@Injectable({
  providedIn: "root"
})
export class InstructorCanLoadGuard implements CanLoad {
  constructor(
    private router: Router,
    private appStateStore: AppStateStore
  ) {}
  
  /**
   * в инструктор можно попасть только если есть активная сессия
   */
  canLoad(): Observable<boolean> {
    return this.appStateStore.session$.pipe(
      map(session => {
        if (session) return true
        else this.router.navigate([""])
      }),
      take(1)
    )
  }
}