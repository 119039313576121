import { Pipe, PipeTransform } from '@angular/core'

const kb = 1024
const mb = 1024 ** 2
const gb = 1024 ** 3

@Pipe({ name: 'megabite' })
export class MegaBitePipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    if (!value) {
      return ""
    } else {
      if (value >= gb)
        return (value / gb).toFixed(2) + " Гб"
      if (value >= mb)
        return (value / mb).toFixed(2) + " Мб"
      if (value >= kb)
        return (value / kb).toFixed(2) + " Кб"
      else
        return value / kb + " байт"
    }
  }
}