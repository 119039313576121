import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { switchMap, tap, take } from "rxjs/operators";
import { Quiz, QuizService } from "src/app/core";

export interface StudentAnswer {
  value: number|number[]
  correct: boolean
}

export interface StudentAnswers {
  [key: number]: StudentAnswer
}

export interface QuizSectionState {
  quiz: Quiz
  answers: StudentAnswers
  loading: boolean
  currentQuestionIndex: number
}

@Injectable()
export class QuizSectionStore extends ComponentStore<QuizSectionState> {
  constructor(
    private quizService: QuizService
  ) {
    super({
      quiz: null,
      answers: {},
      loading: true,
      currentQuestionIndex: null
    })
  }

  init(quiz: Quiz) {
    const answers = quiz.questions.reduce((acc, q) => {
      if (q.answer) {
        let studentAnswer: StudentAnswer = {
          value: q.answer,
          correct: q.checkCorrect(q.answer)
        }
        acc[q.id] = studentAnswer
        return acc
      }
      return acc
    }, {})
    const currentQuestionIndex = Object.keys(answers).length
    // debugger
    this.patchState({ quiz, answers, currentQuestionIndex, loading: false })
  }

  loading$ = this.select(s => s.loading)
  setLoading = this.updater((s, loading: boolean) => ({ ...s, loading }))
  
  quiz$ = this.select(s => s.quiz)
  currentQuestionIndex$ = this.select(s => s.currentQuestionIndex)
  currentQuestion$ = this.select(
    this.quiz$, this.currentQuestionIndex$, 
    (quiz, currentQuestionIndex) => {
      return quiz && currentQuestionIndex != null && quiz.questions[currentQuestionIndex]
    }
  )
  questionsCount$ = this.select(this.quiz$, quiz => quiz.questions.length)
  isLastQuestion$ = this.select(
    this.quiz$, this.currentQuestionIndex$, 
    (quiz, currentQuestionIndex) => currentQuestionIndex == quiz.questions.length - 1
  )
  
  answers$ = this.select(s => s.answers)
  setStudentAnswer(questionId: number, answer: StudentAnswer) {
    this.patchState(s => {
      const answers = { ...s.answers, [questionId]: answer }
      return { answers }
    })
  }
  quizCompleted$ = this.select(
    this.answers$, this.questionsCount$, 
    (answers, questionsCount) => questionsCount == Object.keys(answers).length
  )
  score$ = this.select(
    this.answers$, 
    (answers) => Object.values(answers).filter(a => a.correct).length
  )
  
  quizSuccess$ = this.select(
    this.quizCompleted$,
    this.score$,
    this.quiz$,
    (quizCompleted, score, quiz) => {
      const minCorrectAnswers = quiz.minCorrectAnswers
      return quizCompleted && score >= minCorrectAnswers
    }
  )

  sendAnswer(questionId: number, value: number|number[]): Observable<boolean> {
    return this.select(this.quiz$, quiz => quiz.id).pipe(
      switchMap(quizId => this.quizService.sendAnswer(quizId, questionId, value))
    )
  }

  restart() {
    this.setLoading(true)
    this.select(this.quiz$, quiz => quiz.id).pipe(
      switchMap(quizId => this.quizService.restart(quizId))
    ).subscribe({
      next: () => {
        this.patchState(() => ({ answers: {}, currentQuestionIndex: 0 }))
        this.setLoading(false)
      },
      error: (e) => {
        console.log("can not send result to server", e)
        this.setLoading(false)
      }
    })
  }

  nextQuestion() {
    this.patchState((s) => {
      const currentQuestionIndex = s.currentQuestionIndex
      return { currentQuestionIndex: currentQuestionIndex + 1 }
    })
  }

  next(data: {
    questionId: number, 
    answer: StudentAnswer
  }) {
    const { questionId, answer } = data
    const { value } = answer
    this.setLoading(true)
    // отправляем ответ на сервер (только id вопроса и выбранные опции)
    this.sendAnswer(questionId, value).pipe(
      // записываем ответ в стейт
      tap(() => this.setStudentAnswer(questionId, answer)),
      // последняя / не последняя секция
      switchMap(() => this.isLastQuestion$.pipe(
        take(1),
        tap(isLastQuestion => {
          // если не последняя, переключаем на следующий вопрос
          if (!isLastQuestion) this.nextQuestion()
        })
      ))
    ).subscribe({
      next: () => this.setLoading(false),
      error: (e) => {
        console.log("can not send result to server", e)
        this.setLoading(false)
      }
    })
  }
}