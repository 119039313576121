<div class="form-group" id="form-group-{{ config.id }}">
  <label class="form-control-label" id="form-control-label-{{ config.id }}">
    {{ config.label }}<span *ngIf="config.required" class="required">&nbsp;*</span>
  </label>
  
  <div class="radio-buttons" id="radio-buttons-{{ config.id }}">
    <label
    *ngFor="let controlOption of config.controlOptions"
    for="id-{{ config.id }}-{{ controlOption.id }}"
    class="radio-button"
    id="radio-button-{{ controlOption.id }}"
    >
      <div 
      class="radio-button-dot"
      id="radio-button-dot-{{ controlOption.id }}"
      [ngClass]="{
        'selected': (selectedValue$ | async) == controlOption.id
      }"
      >
        <div 
        class="radio-button-dot-inner"
        id="radio-button-dot-inner-{{ controlOption.id }}"
        [ngClass]="{
          'selected': (selectedValue$ | async) == controlOption.id
        }"
        ></div>
      </div>

      <input
      id="id-{{ config.id }}-{{ controlOption.id }}"
      class="radio-button-form-control" 
      type="radio"
      name="{{ config.id }}"
      (change)="toggleRadioButton(controlOption.id)"
      >

      <div 
      class="radio-button-label"
      id="radio-button-label-{{ controlOption.id }}"
      >{{ controlOption.label }}</div>
    </label>
  </div>
</div>