import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input } from "@angular/core"
import { InteractiveItemVideo, InteractiveItem, Interactive } from "src/app/core/models"
import { Subscription, Observable, fromEvent } from 'rxjs'
import { SafeUrl } from '@angular/platform-browser'
import { FilesCacheService } from "./files-cache.service"
import { tap, last, filter, map, mergeMap, switchMap, shareReplay } from 'rxjs/operators'
import { InteractiveStateService } from './interactive-state.service'
import {TransitionTarget} from "../../../core/models/interactive/transition-target.model";

@Component({
  selector: "interactive-item-video",
  template: `
    <div class="page-container">
      <div class="page page--interactive">
        <div class="page-content-wrap">
          <div class="section section__interactive">
            <loader [visible]="loading">{{ loaderText }}</loader>

            <div class="video-container">
              <div class="manual-start" *ngIf="manualStartRequired">
                <div class="start" (click)="manualStart()">
                  <img src="/assets/interactive/start--default.svg" alt="start interactive">
                </div>
              </div>
              <img class="video-poster" #videoPoster [src]="posterSrc">
              <video class="video"
                #video
                playsinline
                webkit-playsinline
                x-webkit-airplay="allow"
                controlslist="nodownload"
                [src]="videoSrc"
                (click)="onClick($event)"
                (playing)="onPlaying($event)"
                (ended)="onEnded($event)"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class InteractiveItemVideoComponent implements AfterViewInit, OnDestroy {
  @ViewChild('video') private videoElRef: ElementRef
  @ViewChild('videoPoster') private videoPosterlRef: ElementRef
  @Input() private interactiveItem: InteractiveItemVideo
  private dataLoaded: Observable<boolean>
  private currentItem: Observable<boolean>
  private dataLoadedSub: Subscription
  private currentItemSub: Subscription
  private showedCount = 0
  loading: boolean = true
  posterSrc: SafeUrl
  videoSrc: SafeUrl
  loaderText = "Загрузка..."
  manualStartRequired = false

  constructor(
    private cache: FilesCacheService,
    private interactiveStateService: InteractiveStateService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.currentItem = this.interactiveStateService.currentItem$.pipe(
        filter(item => item.id == this.interactiveItem.id),
        map(() => true)
      )

      this.dataLoaded = this.cache.getFiles([
        this.interactiveItem.itemUrl,
        this.interactiveItem.posterUrl
      ]).pipe(
        tap(data => {
          const progress = Math.round(data.reduce((memo, d) => memo + d.progress, 0) / data.length)
          this.loaderText = `Загрузка: ${ progress }%`
        }),
        last(),
        switchMap(data => {
          this.videoSrc = data[0].blob
          this.posterSrc = data[1].blob

          return fromEvent(this.videoPoster, "load").pipe(map(() => true))
        }),
        shareReplay(1)
      )

      this.dataLoadedSub = this.dataLoaded.subscribe(() => this.loading = false)

      this.currentItemSub = this.currentItem.pipe(
          mergeMap(() => this.dataLoaded)
        )
        .subscribe(() => {
          this.showedCount = 0
          const playPromise = this.video.play()
          if (playPromise) playPromise
            .then(p => console.log("play", p))
            .catch(e => {
              console.log("play err", e)
              this.manualStartRequired = true
            })
        })
    })
  }

  manualStart() {
    this.manualStartRequired = false
    this.video.play()
  }

  ngOnDestroy() {
    if (this.currentItemSub) this.currentItemSub.unsubscribe()
    if (this.dataLoadedSub) this.dataLoadedSub.unsubscribe()
  }

  get interactive(): Interactive {
    return this.interactiveStateService.interactive
  }

  get video(): HTMLVideoElement {
    return this.videoElRef && this.videoElRef.nativeElement
  }

  get videoPoster(): HTMLImageElement {
    return this.videoPosterlRef && this.videoPosterlRef.nativeElement
  }

  private hidePoster() {
    this.videoPoster.style.zIndex = "0"
  }

  onClick(e: MouseEvent) {
    e.preventDefault()

    if (!this.video.ended && this.video.paused) {
      this.video.play()
      return
    }

    const clickCoords = this.getInteractiveClickCoords(e),
          mouseX = clickCoords.x,
          mouseY = clickCoords.y,
          currentTime = Math.floor(this.video.currentTime),
          transition = this.interactiveItem.buttonTransitions.find(t => {
            return mouseX >= t.leftX &&
              mouseX <= t.rightX &&
              mouseY >= t.leftY &&
              mouseY <= t.rightY &&
              t.startTime <= currentTime &&
              currentTime <= t.endTime
          })

    if (transition) {
      this.setToTransitionTarget(transition.target)
    }
  }

  private setNextItem(item: InteractiveItem) {
    this.video.load()
    this.interactiveStateService.currentItem = item
  }
  /**
   * TODO: убрать дублирующий код
   */
  getInteractiveClickCoords(e: MouseEvent): {
    x: number
    y: number
  } {
    let target = e.target,
        interactiveHeight: number,
        interactiveWidth: number,
        scale: number,
        viewportHeight: number,
        viewportWidth: number,
        viewportRatio: number

    if (target instanceof HTMLImageElement) {
      interactiveHeight = target.naturalHeight
      interactiveWidth = target.naturalWidth
      viewportHeight = target.height
      viewportWidth = target.width
    } else if (target instanceof HTMLVideoElement) {
      interactiveHeight = target.videoHeight
      interactiveWidth = target.videoWidth
      viewportHeight = target.offsetHeight
      viewportWidth = target.offsetWidth
    }

    viewportRatio = viewportWidth / viewportHeight

    let interactiveRatio = interactiveWidth / interactiveHeight
    scale = viewportRatio < interactiveRatio ? viewportWidth / interactiveWidth : viewportHeight / interactiveHeight

    let offsetX = (viewportWidth - interactiveWidth * scale) / 2,
        offsetY = (viewportHeight - interactiveHeight * scale) / 2

    const x = ((e.offsetX - offsetX) / (viewportWidth - 2 * offsetX)) * 100
    const y = ((e.offsetY - offsetY) / (viewportHeight - 2 * offsetY)) * 100

    return { x, y }
  }

  onPlaying(e: Event) {
    setTimeout(() => {
      this.hidePoster()
    }, 100)
  }

  get canRepeat(): boolean {
    return this.interactiveItem.repeatsCount > 0 && this.showedCount < this.interactiveItem.repeatsCount
  }

  get isLastItem(): boolean {
    return this.interactiveItem.isLast
  }

  finishInteractive() {
    this.interactiveStateService.setCompleted()
  }

  onEnded(e: Event) {
    console.log("onEndedVideo", this.interactiveItem)
    this.showedCount += 1

    if (this.canRepeat) {
      this.video.play()
    }
    else if (this.isLastItem) {
      this.finishInteractive()
    }
    else {
      const transition = this.interactiveItem.endTransition

      if (transition) {
        this.setToTransitionTarget(transition.target)
      }
    }
  }

  private setToTransitionTarget(target: TransitionTarget) {
    if (target.isContentItem) {
      const nextItem = this.interactive.items.find(i => i.id == target.targetInteractiveContentItemId)
      this.interactiveStateService.currentItem = nextItem
    } else if(target.isInteractiveExit) {
      this.finishInteractive()
    }
  }
}
