import { Expose } from 'class-transformer'
import { AnswerOption } from "./answer-option.model"
import { Quiz } from './quiz.model'
import { Exam } from './exam.model'

export abstract class Question {
  @Expose() id: number
  @Expose() text: string
  @Expose() hint: string
  shuffleAnswerOptions: boolean
  answerOptions: AnswerOption[]
  questionType: string
  @Expose() answer: number|number[]
  
  parent: Quiz|Exam

  abstract checkCorrect(value: number|number[]): boolean
}