import { Component, Input, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { ProgramSection } from '../core';
import { ProgramSectionDirective } from './program-section.directive';
import { ExamSectionComponent, InteractiveSectionComponent, QuizSectionComponent, StaticScreenSectionComponent, SystemScreenSectionComponent, VideoSectionComponent } from './sections';
import { FormSectionComponent } from './sections/form-section/form-section.component';

export const contentTypeToSectionComponentMap = new Map<string, Type<any>>([
  ["StaticScreen", StaticScreenSectionComponent],
  ["KioskForm", FormSectionComponent],
  ["Exam", ExamSectionComponent],
  ["SystemScreen", SystemScreenSectionComponent],
  ["Video", VideoSectionComponent],
  ["LessonQuiz", QuizSectionComponent],
  ["Interactive", InteractiveSectionComponent]
])

@Component({
  selector: "program-section-component",
  template: `
    <ng-template programSectionHost></ng-template>
  `
})
export class ProgramSectionComponent {
  @Input() 
  set section(programSection: ProgramSection) {
    this.loadComponent(programSection)
  }

  @ViewChild(ProgramSectionDirective, { static: true }) 
  programSectionHost!: ProgramSectionDirective

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  private resolveComponentClass(s: ProgramSection) {
    const componentClass = contentTypeToSectionComponentMap.get(s.contentType)
    if (componentClass) return componentClass
    throw new Error(`Unknown section contentType: ${ s.contentType }`)
  }

  private loadComponent(s: ProgramSection) {
    const componentClass: Type<any> = this.resolveComponentClass(s)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass)
    const viewContainerRef = this.programSectionHost.viewContainerRef
    viewContainerRef.clear()
    const componentRef = viewContainerRef.createComponent<any>(componentFactory)
    componentRef.instance.section = s
  }
}