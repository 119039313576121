import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { plainToClass } from "class-transformer"
import { iif, Observable, of, throwError } from "rxjs"
import { switchMap, map, retryWhen, concatMap, delay, take } from "rxjs/operators"
import { AppStateStore } from "src/app/app-state.store"
import { Interactive } from "../models"
import { BaseApiService } from "./base-api.service"

@Injectable({
  providedIn: 'root'
})
export class InteractiveService extends BaseApiService {
  constructor(
    private http: HttpClient,
    private appStateStore: AppStateStore
  ) {
    super()
  }

  getInteractive(id: number): Promise<Interactive> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["interactives", id.toString()])
        const headers = { "KIOSK-SESSION": sessionUuid }
        return this.http.get(url, { headers })
      }),
      map(json => plainToClass(Interactive, json["interactive"], {
        excludeExtraneousValues: true 
      })),
      take(1)
    ).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((e, i) => iif(() => i >= 3, throwError(e), of(e).pipe(delay(1000)))) 
      ))
    ).toPromise()
  }

  saveCurrentItem(interactiveId: number, itemId: number): Observable<boolean> {
    return this.appStateStore.sessionUuid$.pipe(
      switchMap((sessionUuid: string) => {
        const url = this.buildUrl(["interactives", interactiveId.toString(), "current_item"])
        const headers = { "KIOSK-SESSION": sessionUuid }
        const data = { item_id: itemId }
        return this.http.post<{ ok: boolean }>(url, data, { headers })
      }),
      map(resp => resp.ok)
    ).pipe(
      retryWhen(errors => errors.pipe(
        concatMap((e, i) => iif(() => i >= 3, throwError(e), of(e).pipe(delay(1000)))) 
      ))
    )
  }
}