import { Component, Input, forwardRef } from '@angular/core'
import { RegistrationFormControl } from 'src/app/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, AbstractControl, Validators } from '@angular/forms'
import { Observable, combineLatest } from "rxjs"
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators'

export interface MonthOption {
  value: number
  label: string
  maxDay: [number, number]
}

@Component({
  selector: "registration-control-date",
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RegistrationControlDateComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => RegistrationControlDateComponent),
    multi: true
  }],
  template: `
    <label [for]="controlConfig.name">
      {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
    </label>

    <div class="date-control">
      <div class="day">
        <label for="day-input">День</label>

        <input class="form-control day-control" 
        #dayInput
        type="number"
        id="day-input"
        min="1"
        [max]="maxDay"
        placeholder="10"
        [formControl]="dayControl"
        (blur)="onDayBlur()"
        (focus)="dayInput.select()"
        />
      </div>
      
      <div class="month">
        <label for="month-input">Месяц</label>

        <ng-select
        id="month-input"
        class="month-control"
        [items]="monthOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="monthPlaceholder"
        [formControl]="monthControl"
        bindLabel="label"
        bindValue="value"
        (blur)="onMonthBlur()"
        ></ng-select>
      </div>
      
      <div class="year">
        <label for="year-input">Год</label>

        <input class="form-control year-control" 
        #yearInput
        type="number"
        id="year-input"
        [formControl]="yearControl"
        placeholder="1990"
        (blur)="onYearBlur()"
        [min]="minYear"
        [max]="maxYear"
        (focus)="yearInput.select()"
        />
      </div>
    </div>
  `,
  styles: [`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .date-control {
      display: flex;
      flex-direction: row;
    }

    .date-control label {
      font-size: 12px;
      margin-bottom: 0;
      color: grey;
    }

    .day {
      width: 50px;
    }

    .day .day-control {
      text-align: center;
    }

    .month {
      flex-grow: 1;
      margin: 0 12px 0 12px;
    }

    .year {
      width: 65px;
    }

    .year .year-control {
      text-align: center;
    }
  `]
})
export class RegistrationControlDateComponent implements ControlValueAccessor {
  @Input() controlConfig: RegistrationFormControl
  @Input() minYear = 1900
  @Input() maxYear = 2020
  disabled = false
  @Input() readonly = false
  private onChange = (value: any) => {}
  private onTouched = () => {}

  monthPlaceholder = "Выберите месяц"

  // Январь Февраль Март Апрель Май Июнь Июль Август Сентябрь Октябрь Ноябрь Декабрь
  monthOptions: MonthOption[] = [
    { label: "Январь", value: 0, maxDay: [31, 31] },
    { label: "Февраль", value: 1, maxDay: [28, 29] },
    { label: "Март", value: 2, maxDay: [31, 31] },
    { label: "Апрель", value: 3, maxDay: [30, 30] },
    { label: "Май", value: 4, maxDay: [31, 31] },
    { label: "Июнь", value: 5, maxDay: [30, 30] },
    { label: "Июль", value: 6, maxDay: [31, 31] },
    { label: "Август", value: 7, maxDay: [31, 31] },
    { label: "Сентябрь", value: 8, maxDay: [30, 30] },
    { label: "Октябрь", value: 9, maxDay: [31, 31] },
    { label: "Ноябрь", value: 10, maxDay: [30, 30] },
    { label: "Декабрь", value: 11, maxDay: [31, 31] },
  ]
  
  dayControl = new FormControl(null, [
    Validators.required
  ])
  monthControl = new FormControl(null, [
    Validators.required
  ])
  yearControl = new FormControl(null, [
    Validators.required,
    Validators.min(this.minYear)
  ])

  dayNumber$: Observable<number> = this.dayControl.valueChanges.pipe(
    distinctUntilChanged()
  )
  monthNumber$: Observable<number> = this.monthControl.valueChanges.pipe(
    distinctUntilChanged(),
    tap(() => this.writeDayValue(this.dayControl.value))
  )
  yearNumber$: Observable<number> = this.yearControl.valueChanges.pipe(
    distinctUntilChanged()
  )
  date$ = combineLatest([
    this.dayNumber$,
    this.monthNumber$,
    this.yearNumber$
  ])
  .pipe(
    map(([day, month, year]) => {
      if (day != null && month != null && year != null) 
        return new Date(year, month, day, 0, 0, 0, 0)
      else
        return null
    }),
    shareReplay(1)
  )
  value$ = this.date$.pipe(map(d => d && d.getTime() / 1000))

  get maxDay(): number {
    if (this.yearControl.value == null || this.monthControl.value == null) {
      return 31 // default
    } 
    const isLeapYear = this.yearControl.value % 4 == 0
    const monthOption = this.monthOptions.find(o => o.value == this.monthControl.value)

    return isLeapYear ? monthOption.maxDay[1] : monthOption.maxDay[0]
  }

  ngOnInit() {
    this.value$.subscribe(v => this.onChange(v))
  }

  validate(c: AbstractControl) {
    const valid = this.dayControl.valid && this.monthControl.valid && this.yearControl.valid
    return valid ? null : {
      date: {
        message: "Неверный формат даты"
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn
  }

  onDayBlur() {
    this.writeDayValue(this.dayControl.value)
  }

  onMonthBlur() {
    
  }

  onYearBlur() {
    this.onTouched()
    this.writeDayValue(this.dayControl.value)
  }

  writeValue(value: number) {
    if (value) {
      const date = new Date(value * 1000),
            day = date.getUTCDate(),
            month = date.getUTCMonth(),
            year = date.getUTCFullYear()
  
      this.writeDayValue(day)
      this.monthControl.setValue(month)
      this.yearControl.setValue(year)
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  private writeDayValue(v: number) {
    if (v == null) this.dayControl.setValue(v)
    else {
      let value = v
      if (value < 1) value = 1
      if (value > this.maxDay) value = this.maxDay
  
      this.dayControl.setValue(value)
    }
  }
}