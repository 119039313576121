import { Component, Input, forwardRef } from '@angular/core'
import { RegistrationFormControl, CityService, City } from 'src/app/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: "registration-control-city",
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RegistrationControlCityComponent),
    multi: true
  }],
  template: `
    <label [for]="controlConfig.name">
      {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
    </label>

    <ng-select
    [formControl]="selectCityControl"
    [items]="cities"
    bindLabel="name"
    bindValue="id"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [readonly]="readonly"
    (change)="onSelectChange($event)"
    (clear)="onSelectChange(null)"
    (blur)="onSelectBlur()"
    >
  </ng-select>
  `,
})
export class RegistrationControlCityComponent implements ControlValueAccessor {
  @Input() controlConfig: RegistrationFormControl
  disabled = false
  @Input() readonly = false
  private _cities: City[] = []
  private _value: number
  private onChange = (value: any) => {}
  private onTouched = () => {}
  selectCityControl: FormControl

  constructor(
    private citiesService: CityService
  ) {}

  get placeholder(): string {
    return "Выберите город"
  }

  get cities(): City[] {
    return this._cities
  }

  set value(value: number) {
    this._value = value
    this.selectCityControl.setValue(value)
    this.onChange(value)
    this.onTouched()
  }

  get value(): number {
    return this._value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn
  }

  ngOnInit() {
    this.selectCityControl = new FormControl(null)
    this.citiesService.getCities().subscribe(cities => this._cities = cities)
  }

  onSelectChange(city: City) {
    if (city) this.value = city.id
    else this.value = null
  }

  onSelectBlur() {
    this.onTouched()
  }

  writeValue(value: number) {
    this.value = value
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}