import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { RegistrationStateStore } from '../registration-state.store';
import { FormControl } from '@angular/forms';
import { CustomValidators, EmailConfirmationService } from 'src/app/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { EmailConfirmation } from '../form/registration-form.component';

export const emailConfirmationCountdown = 30 // sec.

@Component({
  selector: "email-confirmation-component",
  templateUrl: "./email-confirmation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailConfirmationComponent {
  @Input() confirmation: EmailConfirmation
  confirmationCodeInput = new FormControl('', [
    CustomValidators.required("Введите код подтверждения")
  ])
  confirmationKey: string
  valid$ = this.confirmationCodeInput.statusChanges.pipe(
    map(status => status && status == "VALID")
  )
  loading$ = new BehaviorSubject(true)
  btnDisabled$ = this.valid$.pipe(map(valid => !valid))
  countDown$ = timer(0, 1000).pipe(
    map(i => emailConfirmationCountdown - i),
    take(emailConfirmationCountdown + 1)
  )

  constructor(
    private readonly registrationStateStore: RegistrationStateStore,
    private emailConfirmationService: EmailConfirmationService
  ) {}

  ngOnChanges() {
    this.loading$.next(true)
    this.createConfirmation(this.confirmation.email)
      .subscribe({
        next: (key) => {
          this.confirmationKey = key
          this.loading$.next(false)
        }
      })
  }

  createConfirmation(email: string): Observable<string> {
    return this.emailConfirmationService.create(email, "ru").pipe(tap(() => this.startNextSmsDelay()))
  }

  checkConfirmation(confirmation: EmailConfirmation, code: string) {
    this.loading$.next(true)
    this.emailConfirmationService.confirm(this.confirmationKey, code).subscribe(token => {
      console.log("token:", token)
      this.confirmationCodeInput.reset()
      this.registrationStateStore.completeConfirmation(confirmation, token)
    }, err => {
      if (err instanceof HttpErrorResponse && err.error && err.error.error && err.error.error == "invalid_code") {
        const message = err.error.error_description
        this.confirmationCodeInput.setErrors({
          ...this.confirmationCodeInput.errors, invalideCode: { message, code }
        })
      }
      
      this.loading$.next(false)
    })
  }

  private startNextSmsDelay() {
    this.countDown$ = timer(0, 1000).pipe(
      map(i => emailConfirmationCountdown - i),
      take(emailConfirmationCountdown + 1)
    )
  }

  resendSms(confirmation: EmailConfirmation) {
    this.loading$.next(true)
    this.confirmationCodeInput.reset()
    this.createConfirmation(confirmation.email).subscribe(key => {
      this.confirmationKey = key
      this.loading$.next(false)
    })
  }

  back() {
    this.registrationStateStore.setConfirmations([])
    this.registrationStateStore.setFormSubmitted(false)
  }
}