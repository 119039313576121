import { BrowserModule } from "@angular/platform-browser"
import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from "@angular/core"
import { CoreModule } from "src/app/core"
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from "./app-routing.module"
import { SharedModule } from "src/app/shared/shared.module"
import { InstructorModule } from "./instructor/instructor.module"
import { RegistrationModule } from "./registration/registration.module"
import { AppComponent } from "./app.component"
import { InitComponent } from "./init.component"
import { NgxMaskModule, IConfig, initialConfig } from 'ngx-mask'
import { environment } from 'src/environments/environment'
import * as Rollbar from 'rollbar'

/**
 * Rollbar
 */
const isProduction = environment.production
const rollbarConfig = {
  accessToken: '4fa0b5555194403a98e431d3de885e6a',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    project: "instructor-3.0"
  },
  enabled: isProduction
}
export const RollbarService = new InjectionToken<Rollbar>('rollbar')
@Injectable() export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (err.alreadySent) return
    this.rollbar.error(err.originalError || err)
    console.log("handleError:", err)
    err.alreadySent = true
    throw err
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig)
}

/**
 * NgxMaskModule
 */
export const options = (): Partial<IConfig> | (() => Partial<IConfig>) => {
  let options = initialConfig
  const extraPatterns = {
    "L": { pattern: new RegExp('[^\!@#\$%\\d\^\&\*\(\)\_\+\=\\-\<\>\"\№\:\,\\.\;\|\\\\/\`\~§±\?\\[\\]\\{\\}]') }
  }
  let patterns = { ...options.patterns, ...extraPatterns }

  return { ...options, ...{ patterns } }
}

@NgModule({
  declarations: [
    AppComponent,
    InitComponent
  ],
  imports: [
    NgxMaskModule.forRoot(options()),
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    InstructorModule,
    RegistrationModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
