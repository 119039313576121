import { Component } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Session } from "./core"
import { catchError, map, take } from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import { AppStateStore, UrlParams } from "./app-state.store"
import { RegistrationService, ThemeService } from "src/app/core/services"

@Component({
  template: `
    <div class="section">
      <loader [visible]="true">Загрузка...</loader>
    </div>
  `
})
export class InitComponent {

  constructor(
    private appStateStore: AppStateStore,
    private router: Router,
    private route: ActivatedRoute,
    private registration: RegistrationService,
    private themeService: ThemeService
  ) {}

  async ngOnInit(): Promise<any> {
    const { programUuid, externalId, accessToken, ...externalParams } = await this.uriParams as Partial<UrlParams>
    const savedProgramUuid = localStorage.getItem("programUuid")

    this.appStateStore.setExternalParams(externalParams)
    this.appStateStore.setAccessToken(accessToken)

    if (programUuid) {
      await this.themeService.loadCustomTheme(programUuid)
      localStorage.setItem("programUuid", programUuid)
      if (externalId) localStorage.setItem("externalId", externalId)
      this.router.navigate(["registration", programUuid])
    }
    // иначе пытаемся достать programUuid из локалсторадж
    else if (savedProgramUuid) {
      await this.themeService.loadCustomTheme(savedProgramUuid)
      this.router.navigate(["registration", savedProgramUuid])
    }
  }

  get uriParams(): Promise<Object> {
    return combineLatest(this.queryParams$, this.accessToken$, this.state$).pipe(
        map(([queryParams, accessToken, state]) => {
          if(accessToken) {
            return ({...queryParams, ...state, accessToken})
          } else {
            return queryParams
          }
        }),
        take(1)
    ).toPromise()
  }

  get accessToken$(): Observable<Object> {
    return this.route.fragment.pipe(
      map(hash => hash && /access_token=([^&]+)/.exec(hash)[1]),
      take(1)
    )
  }

  get state$(): Observable<Object> {
    return this.route.fragment.pipe(
      map(hash => hash && JSON.parse(atob(/state=([^&]+)/.exec(hash)[1]))),
      take(1)
    )
  }

  get queryParams$(): Observable<object> {
    return this.route.queryParams.pipe(take(1))
  }

  loadSession(uuid: string): Promise<Session> {
    if (!uuid) return null

    return this.registration.getSession(uuid).pipe(
      take(1),
      catchError(() => of(null))
    ).toPromise()
  }
}
