import { Component, Input } from "@angular/core"
import { AnswerOption, MultiselectQuestion } from 'src/app/core'
import { Observable, of, forkJoin, BehaviorSubject, Subscription, combineLatest } from 'rxjs'
import { map, take } from "rxjs/operators"
import { QuizSectionStore } from "../quiz-section.store"

@Component({
  selector: "quiz-multiselect-question-section-component",
  templateUrl: "./quiz-multiselect-question-section.component.html"
})
export class QuizMultiselectQuestionSectionComponent {
  loading: boolean
  
  @Input()
  question: MultiselectQuestion
  answersCount: number

  private selectedAnswerOptionsSubject = new BehaviorSubject(new Set<AnswerOption>())
  readonly selectedAnswerOptions$ = this.selectedAnswerOptionsSubject.asObservable()
  private subs: Subscription[] = []

  selectedValue$ = this.selectedAnswerOptions$.pipe(map(
    options => Array.from(options).map(o => o.id)
  ))

  hasAnswer$ = this.selectedAnswerOptions$.pipe(map(options => options.size == this.answersCount))

  answerCorrect$ = this.selectedAnswerOptions$.pipe(
    map(options => this.question.checkCorrect(Array.from(options).map(o => o.id)))
  )

  questionIndex$ = this.quizStore.currentQuestionIndex$.pipe(map(i => i + 1))

  questionsCount$ = this.quizStore.questionsCount$

  nextBtnCaption$ = this.quizStore.isLastQuestion$.pipe(
    map(isLastQuestion => isLastQuestion ? "Готово" : "Вперед")
  )

  questionAnswerHintTitle$ =  this.answerCorrect$.pipe(
    map(correct => correct ? "Правильно" : "Неправильно")
  )

  constructor(
    private quizStore: QuizSectionStore
  ) {}

  get questionContent() {
    return this.question.text
  }
  
  get answerOptions() {
    return this.question.answerOptions
  }

  ngOnInit() {
    this.loading = true
    this.answersCount = this.question.correctAnswerValue.length
    this.subs.push(
      this.loadImages().subscribe(() => {
        this.loading = false
      })
    )
  }

  ngOnDestroy() {
    this.question = null
    this.subs.forEach(s => s.unsubscribe())
  }
  
  next() {
    combineLatest([this.answerCorrect$, this.selectedValue$]).pipe(take(1))
      .subscribe(([correct, value]) => {
        this.quizStore.next({
          questionId: this.question.id,
          answer: { correct, value }
        })
      })
  }

  private loadImage(img: HTMLImageElement): Observable<boolean> {
    if (img.complete) {
      return of(true)
    }

    return new Observable(observer => {
      img.addEventListener('load', () => {
        observer.next(true)
        observer.complete()
      })

      img.addEventListener('error', () => {
        observer.next(false)
        observer.complete()
      })
    })
  }

  protected loadImages(): Observable<boolean> {
    const images = Array.from(document.querySelectorAll('img'))
    if (images.length == 0) {
      return of(true)
    }
    return forkJoin(images.map(img => this.loadImage(img))).pipe(map(() => true))
  }

  toggleAnswer(answer: AnswerOption) {
    const selectedAnswerOptions = this.selectedAnswerOptionsSubject.getValue()
    const hasOption = selectedAnswerOptions.has(answer)

    if (hasOption) {
      this.selectedAnswerOptionsSubject.next(
        new Set(Array.from(selectedAnswerOptions).filter(o => o.id != answer.id))
      )
    } else {
      this.selectedAnswerOptionsSubject.next(
        new Set([...Array.from(selectedAnswerOptions), answer])
      )
    }
  }
}