import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { plainToClass } from 'class-transformer'
import { BaseApiService } from './base-api.service'
import { City } from '../models/city.model'

export interface CitiesSearchParams {
  ids?: number[]
  countryIds?: number[]
}

export interface CityResponse {
  id: number
  name: string
}

@Injectable({
  providedIn: "root"
})
export class CityService extends BaseApiService {
  constructor(
    private http: HttpClient
  ) {
    super()
  }
  
  private prepareParams(searchParams: CitiesSearchParams): HttpParams {
    const params = new HttpParams()

    if (searchParams.countryIds && searchParams.countryIds.length > 0) {
      params.set('country_ids', searchParams.countryIds.join(","))
    }

    if (searchParams.ids && searchParams.ids.length > 0) {
      params.set('ids', searchParams.ids.join(","))
    }

    return params
  }

  getCities(searchParams: CitiesSearchParams = {}): Observable<City[]> {
    const url = this.buildUrl(["cities"])
    const params = this.prepareParams(searchParams)

    return this.http.get(url, { params }).pipe(
      map(resp => {
        const citiesJson = resp["cities"] as CityResponse[]

        return plainToClass(City, citiesJson, {
          excludeExtraneousValues: true 
        })
      })
    )
  }
}