import { Injectable } from '@angular/core'
import { ComponentStore } from "@ngrx/component-store"
import { Observable, of, EMPTY, combineLatest } from 'rxjs'
import { switchMap, tap, catchError, map, filter, withLatestFrom } from 'rxjs/operators'
import { DictsOptionsIndex, RegistrationForm } from '../core'
import { Confirmation } from './form/registration-form.component'

export interface IError {
  message: string
}

export enum Step {
  Form = "FORM",
  PhoneConfirmation = "PHONE_CONFIRMATION",
  EmailConfirmation = "EMAIL_CONFIRMATION"
}

export interface RegistrationFormParams {
  uuid?: string
  external_id?: string
  phone?: string
  email?: string
  telegram?: string
  [key: string]: any
}

export interface RegistrationState {
  registrationFormConfig: RegistrationForm
  registrationFormParams: RegistrationFormParams
  confirmations: Confirmation[]
  formSubmitted: boolean
  dictsOptionsIndex: DictsOptionsIndex
  error: IError
}

@Injectable()
export class RegistrationStateStore extends ComponentStore<RegistrationState> {
  
  constructor() {
    super({
      registrationFormConfig: null,
      registrationFormParams: null,
      confirmations: [],
      formSubmitted: false,
      dictsOptionsIndex: {},
      error: null
    })
  }

  /**
   * registrationFormConfig
   */
  readonly registrationFormConfig$ = this.select(state => state.registrationFormConfig)
  setRegistrationFormConfig = this.updater((state, registrationFormConfig: RegistrationForm) => ({
    ...state, registrationFormConfig
  }))

  /**
   * confirmations
   */
  readonly confirmation$ = this.select(state => state.confirmations).pipe(
    map(confirmations => confirmations.find(c => c.token == null))
  )
  setConfirmations = this.updater((state, confirmations: Confirmation[]) => ({ ...state, confirmations }))
  completeConfirmation(confirmation: Confirmation, token: string) {
    const confirmations = this.get().confirmations
    const i = confirmations.findIndex(c => c.id == confirmation.id)
    confirmation.token = token

    this.setConfirmations([
      ...confirmations.slice(0, i),
      ...[confirmation], 
      ...confirmations.slice(i + 1)
    ])
  }

  /**
   * registrationParams
   */
  readonly registrationParams$ = this.select(state => state.registrationFormParams)
  setRegistrationParams(registrationFormParams: RegistrationFormParams) {
    this.setState(state => ({ ...state, registrationFormParams }))
  }
  readonly formSubmitted$ = this.select(state => state.formSubmitted)
  setFormSubmitted = this.updater((state, formSubmitted: boolean) => ({ ...state, formSubmitted }))

  /**
   * completed
   */
  completed$ = combineLatest([
    this.confirmation$,
    this.formSubmitted$,
    this.registrationParams$
  ])
  .pipe(map(([confirmation, formSubmitted, params]) => !confirmation && !!params && formSubmitted))

  /**
   * dictsOptionsIndex
   */
  readonly dictsOptionsIndex$ = this.select(s => s.dictsOptionsIndex)
  setDictsOptionsIndex = this.updater((s, dictsOptionsIndex: DictsOptionsIndex) => ({ ...s, dictsOptionsIndex }))

  /**
   * Error
   */
   readonly error$ = this.select(s => s.error)
   setError = this.updater((s, error: IError) => ({ ...s, error }))
}