<div class="page-container" [ngClass]="{
  'page-container--loading': loading$ | ngrxPush,
  'page-container--fullscreen': fullScreen
}">
  <div class="page page--static-screen">
    <div class="page-content-wrap">
      <div class="page-content">
        <loader [visible]="loading$ | ngrxPush">Загрузка...</loader>

        <iframe class="content-iframe" *ngIf="!fullScreen; else fullScreenIframe"
        appIframeResizer
        frameborder="0"
        #iframe
        scrolling="no"
        loading="eager"></iframe>
        
        <ng-template #fullScreenIframe>
          <iframe class="content-iframe"
          frameborder="0"
          #iframe
          scrolling="yes"
          loading="eager"></iframe>
        </ng-template>
      </div>
    </div>

    <!-- navigation -->
    <div class="registration-navigation" *ngIf="!(hideDefaultNavigation$ | ngrxPush)">
      <!-- Кнопка назад -->
      <navigation-button *ngIf="!(prevHidden$ | ngrxPush)"
      type="secondary"
      (click)="prev()">
        Назад
      </navigation-button>

      <!-- Кнопка вперед -->
      <navigation-button 
      type="primary"
      [full]="prevHidden$ | ngrxPush"
      [disabled]="nextDisabled$ | ngrxPush"
      (click)="next()"
      >
        {{ nextBtnCaption$ | ngrxPush }}
      </navigation-button>
    </div>
  </div>
</div>