import { Component, Input, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { Question } from 'src/app/core';
import { QuizMultiselectQuestionSectionComponent, QuizSelectQuestionSectionComponent } from './questions';
import { QuizQuestionDirective } from './quiz-question.directive';

export const contentTypeToSectionComponentMap = new Map<string, Type<any>>([
  ["SelectQuestion", QuizSelectQuestionSectionComponent],
  ["MultiselectQuestion", QuizMultiselectQuestionSectionComponent]
])

@Component({
  selector: "quiz-question-component",
  template: `
    <ng-template quizQuestionHost></ng-template>
  `
})
export class QuizQuestionComponent {
  @Input() 
  set question(q: Question) {
    this.loadComponent(q)
  }
  @ViewChild(QuizQuestionDirective, { static: true }) 
  quizQuestionHost!: QuizQuestionDirective

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  private resolveComponentClass(q: Question) {
    const componentClass = contentTypeToSectionComponentMap.get(q.questionType)
    if (componentClass) return componentClass
    throw new Error(`Unknown section contentType: ${ q.questionType }`)
  }

  private loadComponent(question: Question) {
    const componentClass: Type<any> = this.resolveComponentClass(question)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass)
    const viewContainerRef = this.quizQuestionHost.viewContainerRef
    viewContainerRef.clear()
    const componentRef = viewContainerRef.createComponent<any>(componentFactory)
    componentRef.instance.question = question
  }
}