import { Expose, Type } from 'class-transformer'
import { Question } from "./question.model"
import { SelectQuestion } from "./select-question.model"
import { MultiselectQuestion } from "./multiselect-question.model"
import { Content } from './content.model'

export class Quiz extends Content {
  @Expose({ name: "min_correct_answers" }) minCorrectAnswers: number
  @Expose({ name: "shuffle_questions" }) shuffleQuestions: boolean
  @Expose({ name: "time_limit" }) timeLimit: number

  @Expose()
  @Type(() => Question, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: SelectQuestion, name: "select" },
        { value: MultiselectQuestion, name: "multiselect" }
      ]
    }
  })
  questions: Question[]
}