import { Injectable } from "@angular/core"
import { BehaviorSubject, combineLatest } from "rxjs"
import { map, take } from "rxjs/operators"
import { MultiselectQuestion, Question, Quiz, SelectQuestion } from "src/app/core"

export interface StudentAnswer {
  value: number[]
  correct: boolean
}

export interface StudentAnswers {
  [key: number]: StudentAnswer
}

@Injectable()
export class QuizStateService {
  
  private _studentAnswers = new BehaviorSubject<StudentAnswers>({})
  readonly studentAnswers$ = this._studentAnswers.asObservable()
  setStudentAnswer(questionId: number, answer: StudentAnswer) {
    this._studentAnswers.next({ ...this._studentAnswers.getValue(), [questionId]: answer })
  }

  private _quiz = new BehaviorSubject<Quiz>(null)
  readonly quiz$ = this._quiz.asObservable()
  set quiz(quiz: Quiz) {
    const studentAnswers: StudentAnswers = quiz.questions.reduce((answers, q) => {
      answers[q.id] = {
        value: null,
        correct: null
      }
      return answers
    }, {})
    this._quiz.next(quiz)
    this._studentAnswers.next(studentAnswers)
    this._currentQuestionIndex.next(0)
  }
  get quiz(): Quiz {
    return this._quiz.getValue()
  }

  private _quizCompleted = new BehaviorSubject<boolean>(false)
  readonly quizCompleted$ = this._quizCompleted.asObservable()

  private _score = new BehaviorSubject<number>(0)
  readonly score$ = this._score.asObservable()
  addScore(score: number) {
    const currentScore = this._score.getValue()
    this._score.next(currentScore + score)
  }

  quizSuccess$ = combineLatest([
    this.quizCompleted$,
    this.score$,
    this.quiz$.pipe(map(q => q.minCorrectAnswers))
  ]).pipe(
    map(data => {
      const [quizCompleted, score, minCorrectAnswers] = data
      return quizCompleted && score >= minCorrectAnswers
    })
  )

  private _currentQuestionIndex = new BehaviorSubject<number>(-1)
  readonly currentQuestionIndex$ = this._currentQuestionIndex.asObservable()
  private nextQuestion() {
    const currentQuestionIndex = this._currentQuestionIndex.getValue()
    this._currentQuestionIndex.next(currentQuestionIndex + 1)
  }

  currentQuestion$ = this.currentQuestionIndex$.pipe(
    map(i => this.quiz.questions[i])
  )

  questionsCount$ = this.quiz$.pipe(map(quiz => quiz.questions.length))

  isLastQuestion$ = combineLatest([
    this.quiz$,
    this.currentQuestionIndex$
  ]).pipe(
    map(data => {
      const [quiz, currentQuestionIndex] = data
      return currentQuestionIndex == quiz.questions.length - 1
    })
  )
  
  isFirstQuestion$ = this.currentQuestionIndex$.pipe(
    map(i => i == 0)
  )

  setAnswer(questionId: number, value: number[]) {
    const question = this.quiz.questions.find(q => q.id == questionId)
    const correct = this.checkAnswer(question, value)
    const points = 1

    if (correct) {
      this.addScore(points)
    }
    this.setStudentAnswer(questionId, { correct, value })
  }

  private checkAnswer(question: Question, answer: number[]): boolean {
    if (question instanceof SelectQuestion) {
      return question.correctAnswerValue == answer[0]
    }
    else if (question instanceof MultiselectQuestion) {
      return question.correctAnswerValue.length == answer.length && 
        this.intersect(question.correctAnswerValue, answer).length == question.correctAnswerValue.length
    }
    else {
      throw new Error("Unknown question type")
    }
  }

  private intersect(a: number[], b: number[]): number[] {
    const setB = new Set(b)
    return [...new Set(a)].filter(x => setB.has(x))
  }

  init(quiz: Quiz) {
    this.quiz = quiz
  }

  next() {
    this.isLastQuestion$.pipe(
      take(1)
    ).subscribe(isLastQuestion => {
      if (isLastQuestion) {
        this._quizCompleted.next(true)
      } else {
        this.nextQuestion()
      }
    })
  }

  restart(): void {
    this.quiz$.pipe(
      take(1)
    ).subscribe(
      quiz => {
        const studentAnswers: StudentAnswers = quiz.questions.reduce((answers, q) => {
          answers[q.id] = {
            value: null,
            correct: null
          }
          return answers
        }, {})
        this._quizCompleted.next(false)
        this._studentAnswers.next(studentAnswers)
        this._currentQuestionIndex.next(0)
        this._score.next(0)
      }
    )
  }
}