import { InteractiveItem } from "./interactive-item.model"
import { Interactive } from './interactive.model'

export class InteractiveItemImage extends InteractiveItem {
  parent: Interactive

  kind = "image"

  protected _contentUrls() {
    return [this.itemUrl]
  }
}