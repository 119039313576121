<div class="page-container">
  <div class="page page--quiz">
    <div class="page-content-wrap">
      <div class="page-content page-content__multiselect-question">
        <loader [visible]="loading">Загрузка</loader>

        <div class="quiz-header">
          <h1 class="quiz-title">Тест</h1>
          
          <div class="quiz-subtitle" *ngIf="questionsCount$ | async">
            Вопрос: {{ questionIndex$ | async }} / {{ questionsCount$ | async }}
          </div>
        </div>

        <div class="question">
          <div class="question-content" [innerHTML]="questionContent"></div>
        </div>

        <div class="question-answers-list">
          <label class="answer" for="answer-{{answer['id']}}"
          *ngFor="let answer of answerOptions" [ngClass]="{
            'answer__selected': (selectedAnswerOptions$ | async).has(answer),
            'answer__correct':  answer.correct && (hasAnswer$ | async) && (selectedAnswerOptions$ | async).has(answer),
            'answer__error':   !answer.correct && (hasAnswer$ | async) && (selectedAnswerOptions$ | async).has(answer),
            'answer__disabled': hasAnswer$ | async
          }"
          >
            <input type="checkbox" name="selected_answer" class="answer-select" 
            id="answer-{{answer.id}}"
            [value]="answer.id"
            [disabled]="hasAnswer$ | async"
            (click)="toggleAnswer(answer)">

            <div class="answer-content" 
            [innerHTML]="answer.text"
            ></div>
          </label>
        </div>
        
        <div class="question-answer-hint" 
        *ngIf="hasAnswer$ | async" 
        [ngClass]="{
          'correct': (answerCorrect$ | async),
          'error': !(answerCorrect$ | async)
        }"
        >
          <h5 class="question-answer-hint-title">
            {{ questionAnswerHintTitle$ | async}}
          </h5>

          <ng-container *ngFor="let answer of selectedAnswerOptions$ | async">
            <ng-container *ngIf="answer.hint">
              <p [ngClass]="{
                'question-answer-hint-text-correct': answer.correct,
                'question-answer-hint-text-incorrect': !answer.correct
              }">
                {{ answer.hint }}
              </p>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="registration-navigation">
      <navigation-button
      type="primary"
      [full]="true"
      [disabled]="!(hasAnswer$ | async)"
      (click)="next()"
      >
        {{ nextBtnCaption$ | async }}
      </navigation-button>
    </div>
  </div>
</div>