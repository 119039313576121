import {Component, Input, forwardRef, OnInit} from '@angular/core'
import { RegistrationDictOption, RegistrationFormControl } from 'src/app/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'
import { RegistrationStateStore } from '../registration-state.store'
import { map } from 'rxjs/operators'

@Component({
  selector: 'registration-control-dict',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RegistrationControlDictComponent),
    multi: true
  }],
  template: `
    <label [for]="controlConfig.name">
      {{ controlConfig.label }}<span *ngIf="controlConfig.required" class="required">&nbsp;*</span>
    </label>

    <ng-select
    [formControl]="control"
    [items]="dictItems$ | ngrxPush"
    bindLabel="label"
    bindValue="id"
    [disabled]="disabled"
    [readonly]="readonly"
    (change)="onSelectChange($event)"
    (clear)="onSelectChange(null)"
    (blur)="onSelectBlur()"
    >
  </ng-select>
  `,
})
export class RegistrationControlDictComponent implements ControlValueAccessor, OnInit {
  control: FormControl
  @Input() controlConfig: RegistrationFormControl
  disabled = false
  @Input() readonly = false
  private _value: number
  private onChange = (value: any) => {}
  private onTouched = () => {}

  dictItems$ = this.registrationStateStore.dictsOptionsIndex$.pipe(
    map(dictsOptionsIndex => dictsOptionsIndex[this.controlConfig.name] || [])
  )

  constructor(
    private readonly registrationStateStore: RegistrationStateStore
  ) {}

  set value(value: number) {
    this._value = value
    this.control.setValue(value)
    this.onChange(value)
    this.onTouched()
  }

  get value(): number {
    return this._value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn
  }

  ngOnInit() {
    this.control = new FormControl(null)
  }

  onSelectChange(o: RegistrationDictOption) {
    if (o) {
      this.value = o.id
    } else {
      this.value = null
    }
  }

  onSelectBlur() {
    this.onTouched()
  }

  writeValue(value: number) {
    this.value = value
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }
}
