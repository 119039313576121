import { Expose, Type } from 'class-transformer'
import { InteractiveItem } from "./interactive-item.model"
import { InteractiveItemImage } from "./interactive-item-image.model"
import { InteractiveItemVideo } from "./interactive-item-video.model"
import { Content } from '../content.model'


export class Interactive extends Content {  
  @Expose({ name: "content_items" })
  @Type(() => InteractiveItem, {
    discriminator: {
      property: "kind",
      subTypes: [
        { value: InteractiveItemImage, name: "image" },
        { value: InteractiveItemVideo, name: "video" }
      ]
    }
  })
  items: InteractiveItem[]

  @Expose({ name: "current_item_id" }) currentItemId: number

  init() {
    this.items.forEach(i => i.init())
    this.sortContentItems()
  }

  private sortContentItems() {
    const items = this.items
    console.log(items.map(i => i.id))

    if (!items || items.length == 0) {
      return []
    }

    // stack
    let s: InteractiveItem[] = []
    let explored: Set<number> = new Set()

    s.push(items[0])
    explored.add(items[0].id)
    
    while (s.length != 0) {
      let t = s.pop()
    
      t.connectedItemIds.filter(id => !explored.has(id)).forEach(id => {
        const item = items.find(i => i.id == id)
        if (item) {
          explored.add(id)
          s.push(item)
        }
      })
    }

    const order = Array.from(explored)

    this.items = items.sort((a, b) => {
      return order.indexOf(a.id) > order.indexOf(b.id) ? 
        1 : order.indexOf(a.id) < order.indexOf(b.id) ? -1 : 0
    })
  }
}