import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { HttpClient } from '@angular/common/http'
import { BaseApiService } from './base-api.service'

export interface PhoneConfirmation {
  key?: string
  deny_resend_until?: number
  token?: string
}

@Injectable({
  providedIn: "root"
})
export class PhoneConfirmationService extends BaseApiService {

  constructor(private http: HttpClient) {
    super()
  }

  create(phone: string, locale: string): Observable<string> {
    const url = this.buildUrl(["phone_confirmations"])
    const phone_confirmation = { phone, locale }

    return this.http.post(url, phone_confirmation).pipe(
      map((resp: {
        phone_confirmation: PhoneConfirmation
      }) => {
        return resp.phone_confirmation.key
      })
    )
  }

  confirm(key: string, code: string): Observable<string> {
    const url = this.buildUrl(["phone_confirmations", "confirm"])
    const phone_confirmation = { key, code } 

    return this.http.patch(url, phone_confirmation).pipe(
      map((resp: {
        phone_confirmation: PhoneConfirmation
      }) => {
        return resp.phone_confirmation.token
      })
    )
  }
}