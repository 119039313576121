<!-- Загрузка -->
<loader [visible]="loading">Загрузка</loader>

<ng-container *ngIf="kioskForm">
  <div class="page-container" *ngIf="kioskForm.submitted; else kioskFormContent">
    <div class="page page--kioskForm">
      <div class="page-content-wrap">
        <div class="page-content page-content--system-screen">
          <svg-form-submitted></svg-form-submitted>
          
          <p>Данные уже были отправлены</p>
        </div>
      </div>
      <!-- навигация -->
      <div class="registration-navigation" *ngIf="kioskForm && form">
        <!-- Кнопка назад -->
        <navigation-button type="secondary" (click)="prev()">
          Назад
        </navigation-button>

        <!-- Кнопка вперед -->
        <navigation-button type="primary" (click)="next()">
          Далее
        </navigation-button>
      </div>
    </div>
  </div>

  <ng-template #kioskFormContent>
    <div class="page-container">
      <div class="page page--kioskForm">
        <div class="page-content-wrap">
          <div class="page-content page-content--kioskForm">
            <!-- Загрузка -->
            <loader [visible]="loading">Загрузка</loader>
    
            <!-- Форма -->
            <form *ngIf="!!kioskForm && form" [formGroup]="form">
              <div class="controls">
                <div class="control"
                *ngFor="let controlConfig of kioskForm.controls"
                >
                  <long-text-control
                  *ngIf="controlConfig.controlType == 'longText'"
                  [formControlName]="controlConfig.id"
                  [config]="controlConfig"
                  ></long-text-control>

                  <files-control
                  *ngIf="controlConfig.controlType == 'files'"
                  [formControlName]="controlConfig.id"
                  [config]="controlConfig"
                  ></files-control>

                  <single-select-control
                  *ngIf="controlConfig.controlType == 'singleSelect'"
                  [formControlName]="controlConfig.id"
                  [config]="controlConfig"
                  ></single-select-control>

                  <show-errors [control]="form.controls[controlConfig.id]"></show-errors>
                </div>
              </div>
            </form>
          </div>
        </div>
    
        <!-- навигация -->
        <div class="registration-navigation" *ngIf="kioskForm && form">
          <!-- Кнопка вперед -->
          <navigation-button 
          type="primary"
          [disabled]="!form.valid"
          [full]="true"
          (click)="submit()"
          >
            {{ kioskForm.btnNextLabel }}
          </navigation-button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>