import { Component } from '@angular/core'
import { RegistrationStateStore } from './registration-state.store'
import { of } from 'rxjs'
import { catchError, switchMap, take } from 'rxjs/operators'
import { Session, RegistrationService, ProgramService, RegistrationConfig, ThemeService } from '../core'
import { AppStateStore } from "../app-state.store"
import { Router } from '@angular/router'
import { Confirmation, EmailConfirmation, PhoneConfirmation } from './form/registration-form.component'

@Component({
  templateUrl: "./registration.component.html"
})
export class RegistrationComponent {
  registrationFormConfig$ = this.registrationStateStore.registrationFormConfig$
  confirmation$ = this.registrationStateStore.confirmation$
  completed$ = this.registrationStateStore.completed$
  formSubmitted$ = this.registrationStateStore.formSubmitted$
  error$ = this.registrationStateStore.error$

  showConfirmation$ = this.registrationStateStore.select(
    this.registrationStateStore.formSubmitted$,
    this.registrationStateStore.confirmation$,
    (formSubmitted, confirmation) => formSubmitted && !!confirmation
  )

  constructor(
    private readonly registrationStateStore: RegistrationStateStore,
    private readonly registrationService: RegistrationService,
    private readonly programService: ProgramService,
    private readonly appStateStore: AppStateStore,
    private readonly router: Router,
    private readonly themeService: ThemeService
  ) {}

  async ngOnInit(): Promise<any> {
    console.log("RegistrationComponent -> ngOnInit()")
    const programUuid = localStorage.getItem("programUuid")
    const externalId = localStorage.getItem("externalId")
    const sessionUuid = localStorage.getItem("session")
    await this.themeService.loadCustomTheme(programUuid)
    const currentSession = await this.getCurrentSession(sessionUuid)
    this.appStateStore.setExternalId(externalId)
    this.appStateStore.setProgramUuid(programUuid)
    /**
     * Продолжаем сессию, если можно
     */
    if (this.canContinueSession(currentSession, programUuid, externalId, sessionUuid)) {
      this.appStateStore.setSession(currentSession)
      this.router.navigate(["instructor"])
    }
    /**
     * Загружаем конфиг регистрации
     */
    else {
      try {
        const {registrationForm, dictsOptionsIndex} = await this.getRegistrationConfig(programUuid)

        if (registrationForm && registrationForm.controls.length > 0) {
          this.registrationStateStore.setDictsOptionsIndex(dictsOptionsIndex)
          this.registrationStateStore.setRegistrationFormConfig(registrationForm)
        } else {
          this.registrationStateStore.setRegistrationParams({})
          this.registrationStateStore.setFormSubmitted(true)
        }
      } catch (e) {
        if (e['status'] == 404) {
          this.registrationStateStore.setError({message: 'Запись не найдена'});
        }
        if (e.error?.error == 'YandexID') {
          this.appStateStore.state$.subscribe(state => {
            const yandexOAuthUrl = new URL('https://oauth.yandex.ru/authorize')

            Object.entries({
              response_type: 'token',
              client_id: '00a04fd10c994185b7d2a0d86bf35367',
              redirect_uri: window.location.origin,
              state: btoa(JSON.stringify(state)),
              force_confirm: 'true'
            }).forEach(([key, value]) => yandexOAuthUrl.searchParams.set(key, value))

            document.location.href = yandexOAuthUrl.href
          })
        }
      }
    }
  }

  private getRegistrationConfig(programUuid: string): Promise<RegistrationConfig> {
    return this.appStateStore.accessToken$.pipe(
      switchMap(token => this.programService.getRegistrationConfig(programUuid, {access_token: token}))
    ).pipe(take(1)).toPromise()
  }

  private getCurrentSession(sessionId: string): Promise<Session> {
    if (!sessionId) sessionId = localStorage.getItem("session")
    if (!sessionId) return of(null).toPromise()
    else return this.registrationService.getSession(sessionId).pipe(
      catchError(() => of(null))
    ).toPromise()
  }

  private canContinueSession(
    session: Session,
    programUuid: string,
    externalId: string,
    sessionUuid: string
  ): boolean
  {
    if (session && session.isActive) {
      if (programUuid && session.programUuid != programUuid) return false
      if (externalId && session.externalId != externalId) return false
      if (sessionUuid && session.uuid != sessionUuid) return false
      return true
    }
    return false
  }

  isPhoneConfirmation(confirmation: Confirmation) {
    return confirmation instanceof PhoneConfirmation
  }

  isEmailConfirmation(confirmation: Confirmation) {
    return confirmation instanceof EmailConfirmation
  }
}
