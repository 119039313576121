<div class="page-container">
  <div class="page page--video">
    <div class="page-content-wrap">
      <div class="page-content page-content--video">
        <!-- видео -->
        <video-player
        [videoUrl]="videoUrl"
        [maxWatched]="maxWatched$ | ngrxPush"
        (error)="onVideoError($event)"
        (playing)="onVideoPlaying()"
        (ended)="onVideoEnded()"
        (maxWatched)="onMaxWatched($event)"
        ></video-player>
      </div>
    </div>

    <!-- навигация -->
    <div class="registration-navigation">
      <!-- Кнопка назад -->
      <navigation-button *ngIf="!(prevHidden$ | ngrxPush)"
      type="secondary"
      [disabled]="(prevDisabled$ | ngrxPush)"
      (click)="prev()">
        Назад
      </navigation-button>

      <!-- Кнопка вперед -->
      <navigation-button 
      type="primary"
      [full]="prevHidden$ | ngrxPush"
      [disabled]="nextDisabled$ | ngrxPush"
      (click)="next()"
      >
        {{ nextBtnCaption$ | ngrxPush }}
      </navigation-button>
    </div>
  </div>
</div>