import { Expose } from 'class-transformer'
import { InteractiveItem } from "./interactive-item.model"
import { Interactive } from './interactive.model'

export class InteractiveItemVideo extends InteractiveItem {
  @Expose({ name: "poster_url" }) posterUrl: string
  @Expose({ name: "repeats_count" }) repeatsCount: number

  parent: Interactive

  kind = "video"

  protected _contentUrls() {
    return [this.itemUrl, this.posterUrl]
  }
}