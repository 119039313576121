import { NgModule } from "@angular/core"
import { SharedModule } from "src/app/shared/shared.module"
import { InstructorRoutingModule } from "./instructor-routing.module"
import { InstructorComponent } from "./instructor.component"
import { ProgramSectionComponent } from "./program-section.component"
import { InstructorErrorComponent } from "./instructor-error.component"
import { 
  StaticScreenSectionComponent,
  VideoSectionComponent,
  QuizSectionComponent,
  QuizSelectQuestionSectionComponent,
  QuizMultiselectQuestionSectionComponent,
  InteractiveSectionComponent,
  InteractiveItemImageComponent,
  InteractiveItemVideoComponent,
  ExamSectionComponent,
  ExamSelectQuestionSectionComponent,
  ExamMultiselectQuestionSectionComponent,
  ExamCompletedComponent,
  SystemScreenSectionComponent,
  QuizCompletedSuccessComponent,
  QuizCompletedFailureComponent
} from "./sections"
import { ProgramSectionDirective } from "./program-section.directive"
import { FormSectionComponent } from "./sections/form-section/form-section.component"
import { QuizQuestionDirective } from "./sections/quiz-section/quiz-question.directive"
import { QuizQuestionComponent } from "./sections/quiz-section/quiz-question.component"
import { LongTextControlComponent } from "./sections/form-section/long-text-control/long-text-control.component"
import { SingleSelectControlComponent } from "./sections/form-section/single-select-control/single-select-control.component"
import { FilesControlComponent } from "./sections/form-section/files-control/files-control.component"
import { ReactiveFormsModule } from "@angular/forms"

@NgModule({
  declarations: [
    InstructorComponent,
    ProgramSectionComponent,
    ProgramSectionDirective,
    StaticScreenSectionComponent,
    VideoSectionComponent,
    QuizSectionComponent,
    QuizSelectQuestionSectionComponent,
    QuizMultiselectQuestionSectionComponent,
    QuizCompletedSuccessComponent,
    QuizCompletedFailureComponent,
    InteractiveSectionComponent,
    InteractiveItemImageComponent,
    InteractiveItemVideoComponent,
    ExamSectionComponent,
    ExamSelectQuestionSectionComponent,
    ExamMultiselectQuestionSectionComponent,
    ExamCompletedComponent,
    SystemScreenSectionComponent,
    FormSectionComponent,
    LongTextControlComponent,
    FilesControlComponent,
    SingleSelectControlComponent,
    InstructorErrorComponent,
    QuizQuestionComponent,
    QuizQuestionDirective
  ],
  imports: [
    SharedModule,
    InstructorRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    
  ]
})
export class InstructorModule { }
