import { Expose } from 'class-transformer'

export class CustomTheme {
  @Expose({ name: "main_color" }) mainColor: string
  @Expose({ name: "failure_color" }) failureColor: string
  // button colors
  @Expose({ name: "btn_color_primary" }) btnColorPrimary: string
  @Expose({ name: "btn_color_secondary" }) btnColorSecondary: string
  @Expose({ name: "btn_color_disabled" }) btnColorDisabled: string
  // button text colors
  @Expose({ name: "btn_text_color_primary" }) btnTextColorPrimary: string
  @Expose({ name: "btn_text_color_secondary" }) btnTextColorSecondary: string
  @Expose({ name: "btn_text_color_disabled" }) btnTextColorDisabled: string
}