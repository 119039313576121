<div class="page-container" *ngIf="loading$ | ngrxPush as loading; else alreadyLoaded">
  <div class="page page--exam">
    <div class="page-content-wrap">
      <div class="page-content page-content--system-screen">
        <loader [visible]="loading">Загрузка</loader>
      </div>
    </div>
  </div>
</div>

<ng-template #alreadyLoaded>
  <ng-container *ngIf="!(completed$ | ngrxPush); else examCompleted">
    <ng-container *ngrxLet="currentQuestion$ as question">
      <exam-select-question-section-component
      [question]="question"
      *ngIf="question.questionType == 'SelectQuestion'"
      (next)="next()"
      ></exam-select-question-section-component>
      
      <exam-multiselect-question-section-component
      [question]="question"
      *ngIf="question.questionType == 'MultiselectQuestion'"
      (next)="next()"
      ></exam-multiselect-question-section-component>
    </ng-container>
  </ng-container>
  
  <ng-template #examCompleted>
    <exam-completed-component [section]="section"></exam-completed-component>
  </ng-template>
</ng-template>