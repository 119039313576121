<div class="page">
  <div class="page-title">Подтверждение телефона</div>

  <div class="page-content">
    
    На номер {{ confirmation.phone }} отправлено сообщение с 6-х значным кодом, введите его в поле ниже
    
    <div class="form-group">
      <input class="form-control" type="text" 
      [formControl]="confirmationCodeInput"
      #confirmationCode
      [readOnly]="loading$ | ngrxPush"
      >
      <show-errors [control]="confirmationCodeInput"></show-errors>
    </div>

    <div style="position: relative; height: 100px;">
      <loader [visible]="loading$ | ngrxPush"></loader>
      <span *ngIf="(countDown$ | ngrxPush) > 0">Отправить смс повторно можно через {{ countDown$ | ngrxPush }} скунд</span>
      <a *ngIf="(countDown$ | ngrxPush) == 0" href="#" (click)="$event.preventDefault(); resendSms(confirmation)">Отправить смс повторно</a>
    </div>
    

    <div class="registration-submit">
      <navigation-button
      type="primary"
      (click)="checkConfirmation(confirmation, confirmationCode.value)"
      [disabled]="!(valid$ | ngrxPush) || (loading$ | ngrxPush)"
      [full]="true"
      >
        Далее
      </navigation-button>
    </div>
  </div>
</div>