import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { InstructorModule } from "./instructor/instructor.module"
import { RegistrationModule } from "./registration/registration.module"
import { InitComponent } from "./init.component"
import { InstructorCanLoadGuard } from "./instructor/instructor-can-load.guard"
import { RegistrationGuard } from "./registration/registration.guard"


const routes: Routes = [
  { 
    path: "", 
    component: InitComponent, 
    pathMatch: "full" 
  },
  { 
    path: "instructor", 
    canLoad: [InstructorCanLoadGuard],
    loadChildren: () => InstructorModule 
  },
  { 
    path: "registration/:programUuid", 
    // canLoad: [RegistrationGuard],
    loadChildren: () =>  RegistrationModule
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
