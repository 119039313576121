import { Injectable } from "@angular/core"
import { Observable, of, combineLatest, from } from 'rxjs'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'
import { HttpClient, HttpRequest, HttpEventType, HttpErrorResponse } from '@angular/common/http'
import { map, catchError, shareReplay, mergeAll } from 'rxjs/operators'
import cyrb53 from 'src/app/core/utils/cyrb53'

export interface FileCache {
  blob: SafeUrl
  progress: number
  status: number
}

@Injectable()
export class FilesCacheService {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  cacheIndex: { [key: string]: Observable<FileCache> } = {}

  preloadFiles(urls: string[]) {
    this.cacheIndex = urls.reduce((memo, url) => {
      const key = cyrb53(url)
      memo[key] = this.loadFile(url).pipe(shareReplay(1))
      return memo
    }, {})
    
    from(Object.values(this.cacheIndex) as Observable<FileCache>[]).pipe(
      mergeAll(4)
    )
    .subscribe({
      complete: () => {
        console.log("DATA PRELOADED!")
      }
    })
  }

  getFile(url: string): Observable<FileCache> {
    const key = cyrb53(url)
    return this.cacheIndex[key]
  }

  getFiles(urls: string[]): Observable<FileCache[]> {
    const keys = urls.map(url => cyrb53(url))

    return combineLatest(keys.map(k => this.cacheIndex[k]))
  }

  loadFile(url: string): Observable<FileCache> {
    console.log("loadFile:", url)
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
      responseType: "blob"
    })
    let progress = 0
    let blob: SafeUrl
    let status: number

    return this.http.request(req).pipe(
      map(event => {
        if (event.type == HttpEventType.DownloadProgress) {
          progress = Math.round(event.loaded / event.total * 100)
        }

        if (event.type == HttpEventType.Response && event.ok) {
          blob = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(event.body))
        }

        return { blob, progress, status }
      }),
      catchError((resp: HttpErrorResponse) => {
        const error = resp.error
        status = resp.status

        if (resp.status == 0) {
          console.log("Пропал интернет")

          if (error instanceof ProgressEvent) {
            progress = Math.round(error.loaded / error.total * 100)
            throw new Error("Can not load file!")
          }
        } else {
          throw resp
        }

        return of({ blob, progress, status })
      })
    )
  }
}