import { Component, Input, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, FormBuilder } from '@angular/forms'
import { Subscription, BehaviorSubject } from 'rxjs'
import { KioskFormControl, FilesControlCongig } from 'src/app/core'
import { MegaBitePipe } from 'src/app/shared/pipes/megabite.pipe'

@Component({
  selector: "files-control",
  providers: [{ 
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FilesControlComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FilesControlComponent),
    multi: true
  }],
  templateUrl: "./files-control.component.html",
  styleUrls: ["./files-control.component.sass"]
})
export class FilesControlComponent implements ControlValueAccessor {
  @Input() config: KioskFormControl
  formCcontrol = this.fb.control(null)

  multiFileSelect: boolean
  maxFiles = 10
  maxSize = 25 * 1024 * 1024
  helpText: string
  
  private selectedFilesSubject = new BehaviorSubject<File[]>([])
  readonly selectedFiles$ = this.selectedFilesSubject.asObservable()
  setSelectedFiles(files: File[]) {
    this.selectedFilesSubject.next(files)
  }

  onTouched: Function = () => {}

  private subs: Subscription[] = []

  constructor (
    private fb: FormBuilder,
    private megabitePipe: MegaBitePipe
  ) {}

  ngOnInit() {
    const controlParams = this.config.params as FilesControlCongig
    this.multiFileSelect = controlParams.multi_file_select

    if (this.multiFileSelect) {
      this.helpText = `До ${ this.maxFiles } файлов (общий размер — до ${ this.megabitePipe.transform(this.maxSize) }).`
    } else {
      this.helpText = `Один файл размером до ${ this.megabitePipe.transform(this.maxSize) }.`
    }

    this.subs.push(
      this.selectedFiles$.subscribe(selectedFiles => {
        this.onTouched()

        this.formCcontrol.setValue(selectedFiles)
      })
    )
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  registerOnChange(onChange: any) {
    this.subs.push(this.formCcontrol.valueChanges.subscribe(onChange))
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formCcontrol.disable()
    }
    else {
      this.formCcontrol.enable()
    }
  }

  writeValue(value: any) {
    if (value) {
      this.formCcontrol.setValue(value, { emitEvent: false })
    }
  }
  
  validate(c: AbstractControl) {
    const selectedFiles = this.selectedFilesSubject.getValue()
    const selectedFilesSize = selectedFiles.reduce((memo, file) => {
      return memo += file.size
    }, 0)
    let errors = {}
    
    if (this.maxFiles && selectedFiles.length > this.maxFiles) {
      const maxFiles = {
        files: selectedFiles.length,
        max: this.maxFiles,
        message: `Максимальное количество файлов: ${ this.maxFiles }, выбрано: ${ selectedFiles.length }`
      }
      errors = { ...errors, maxFiles }
    }

    if (this.maxSize && selectedFilesSize > this.maxSize) {
      const maxSize = {
        size: selectedFilesSize,
        max: this.maxSize,
        message: `Максимальных размер загружаемых файлов: ${ this.megabitePipe.transform(this.maxSize) }, выбрано: ${this.megabitePipe.transform(selectedFilesSize)}.`
      }
      errors = { ...errors, maxSize }
    }
    
    if (this.config.required && selectedFiles.length == 0) {
      const required = {
        message: "Обязательное поле"
      }
      errors = { ...errors, required }
    }

    return Object.keys(errors).length == 0 ? null : errors
  }

  private updateFiles(files: FileList) {
    const selectedFiles = this.selectedFilesSubject.getValue()
    this.setSelectedFiles([...selectedFiles, ...Array.from(files)])
  }

  private changeFiles(files: FileList) {
    this.setSelectedFiles(Array.from(files))
  }

  remove(index: number) {
    const selectedFiles = this.selectedFilesSubject.getValue()
    selectedFiles.splice(index, 1)
    this.setSelectedFiles(selectedFiles)
  }

  handleFilesChange(files: FileList) {
    if (this.multiFileSelect) {
      this.updateFiles(files)
    } else {
      this.changeFiles(files)
    }
  }
}